import { useEffect } from "react";

import { useLocation } from "react-router-dom";

const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined" && window.sa_event) {
      window.sa_event("pageview", { url: location.pathname });
    }
  }, [location]);
};

export default usePageViews;
