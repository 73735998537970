import { doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import { app, db } from "../../firebase";

export const initiateLateCheckout = async (
  orderId: string,
  database: string = "b2cOrders"
) => {
  const functions = getFunctions(app, "europe-west1");
  console.log("Initiating late checkout for order", orderId);

  try {
    const orderRef = doc(db, database, orderId);
    const orderSnap = await getDoc(orderRef);

    if (!orderSnap.exists()) {
      throw new Error("Order does not exist");
    }

    const orderData = orderSnap.data();
    console.log("Order data", orderData.contactInfo.phone);

    const createVippsCheckout = httpsCallable(
      functions,
      "createVippsCheckoutFiksePortal"
    );

    const result = await createVippsCheckout(orderData);

    if (result.data && typeof result.data === "object" && "url" in result.data) {
      const paymentUrl = (result.data as { url: string }).url;
      window.location.href = paymentUrl;
    } else {
      throw new Error("Unexpected response from Vipps checkout function");
    }
  } catch (error) {
    console.error("Error initiating late checkout:", error);
    throw error;
  }
};
