import React, { useEffect, useRef, useState } from "react";

import { CheckIcon } from "@heroicons/react/24/outline";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import { use } from "i18next";
import { useTranslation } from "react-i18next";

import { ReactComponent as CameraIcon } from "../../images/icons-fikse/capture image.svg";
import { ReactComponent as XMarkIcon } from "../../images/icons-fikse/close.svg";
import { ReactComponent as PhotoIcon } from "../../images/icons-fikse/media.svg";

interface CameraCaptureProps {
  onClose: (capturedImage?: string) => void;
  loading: boolean;
}

const CameraCapture: React.FC<CameraCaptureProps> = ({ onClose, loading }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [model, setModel] = useState<cocoSsd.ObjectDetection | null>(null);
  const [feedback, setFeedback] = useState<string>("");
  const [isCentralized, setIsCentralized] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [hasCalledOnClose, setHasCalledOnClose] = useState<boolean>(false);
  const [deleteThisThisIsBad, setDeleteThisThisIsBad] = useState<boolean>(true);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const isMobile = window.innerWidth <= 768; // Simple check for mobile screens

  const { t } = useTranslation(); // Add translation hook

  useEffect(() => {
    setFeedback(t("camera.placeGarment")); // Set the initial feedback text
    console.log("CameraCapture mounted.");
    const loadModel = async () => {
      console.log("Loading model...");
      const loadedModel = await cocoSsd.load();
      setModel(loadedModel);
    };

    loadModel();

    console.log("t", t);

    if (!isMobile && deleteThisThisIsBad) {
      fileInputRef.current?.click();
    } else {
      startCamera();
    }
    setDeleteThisThisIsBad(false);
  }, [t]);

  useEffect(() => {
    if (capturedImage && !hasCalledOnClose) {
      setHasCalledOnClose(true);
      onClose(capturedImage);
    }
  }, [capturedImage, onClose, hasCalledOnClose]);

  const startCamera = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: {
          facingMode: "environment",
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      };

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            if (videoRef.current) {
              console.log("Video stream set:", stream);
              videoRef.current.play();
              detectFrame();
            }
          };
        }
      } catch (err) {
        console.error("Error accessing camera: ", err);
      }
    }
  };

  const detectFrame = async () => {
    if (model && videoRef.current && canvasRef.current) {
      if (videoRef.current.videoWidth === 0 || videoRef.current.videoHeight === 0) {
        requestAnimationFrame(detectFrame);
        return;
      }

      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;

      if (
        canvasRef.current.width !== videoWidth ||
        canvasRef.current.height !== videoHeight
      ) {
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;
        console.log("Canvas dimensions set:", videoWidth, videoHeight);
      }

      const predictions = await model.detect(videoRef.current);
      giveFeedback(predictions, videoWidth, videoHeight);

      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
      requestAnimationFrame(detectFrame);
    }
  };

  const giveFeedback = (
    predictions: cocoSsd.DetectedObject[],
    videoWidth: number,
    videoHeight: number
  ) => {
    let feedbackMessage = "";
    let centralized = false;

    if (predictions.length === 0) {
      feedbackMessage = t("camera.noObjects");
    } else {
      const mainPrediction = predictions[0];
      const [x, y, width, height] = mainPrediction.bbox;

      const objectCenterX = x + width / 2;
      const objectCenterY = y + height / 2;

      const frameCenterX = videoWidth / 2;
      const frameCenterY = videoHeight / 2;

      const offsetX = Math.abs(frameCenterX - objectCenterX);
      const offsetY = Math.abs(frameCenterY - objectCenterY);

      if (width > videoWidth * 0.9 || height > videoHeight * 0.9) {
        feedbackMessage = t("camera.keepVisible");
      } else if (offsetX > frameCenterX * 0.5 || offsetY > frameCenterY * 0.5) {
        feedbackMessage = t("camera.centerGarment");
      } else {
        feedbackMessage = t("camera.looksGood");
        centralized = true;
      }
    }

    if (centralized && !isLocked) {
      setIsLocked(true);
      setIsCentralized(true);
      setTimeout(() => {
        setIsLocked(false);
      }, 1000);
    } else if (!centralized && !isLocked) {
      setIsCentralized(false);
    }

    setFeedback(feedbackMessage);
  };

  useEffect(() => {
    if (model) {
      console.log("Starting detection...");
      detectFrame();
    }
  }, [model]);

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;

        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;

        ctx.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
        const imageDataUrl = canvasRef.current.toDataURL("image/png");
        setCapturedImage(imageDataUrl);
        localStorage.setItem("capturedImage", imageDataUrl);
        setFeedback(t("camera.satisfied"));
      }
    }
  };

  const clearCapturedImage = () => {
    setCapturedImage(null);
    localStorage.removeItem("capturedImage");
    setHasCalledOnClose(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    startCamera();
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const image = reader.result as string;
          setCapturedImage(image);
          localStorage.setItem("capturedImage", image);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="relative h-full w-full bg-black">
      <div className="relative h-full w-full">
        {capturedImage ? (
          <img
            src={capturedImage}
            alt="Captured"
            className="absolute inset-0 h-full w-full object-cover"
          />
        ) : (
          <>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              className="absolute inset-0 h-full w-full object-cover"
              style={{ objectFit: "cover" }}
            />
            <canvas
              ref={canvasRef}
              className="pointer-events-none absolute inset-0 h-full w-full"
            />
          </>
        )}
        {isMobile && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div
              className={`relative h-4/5 w-4/5 opacity-100 ${
                isCentralized ? "border-green-500" : "border-white"
              }`}
            >
              <div
                className="absolute inset-0 border-4 border-transparent"
                style={{ pointerEvents: "none" }}
              >
                <div className="absolute left-0 top-0 h-6 w-6 rounded-tl-2xl border-l-2 border-t-2" />
                <div className="absolute right-0 top-0 h-6 w-6 rounded-tr-2xl border-r-2 border-t-2" />
                <div className="absolute bottom-24 left-0 h-6 w-6 rounded-bl-2xl border-b-2 border-l-2" />
                <div className="absolute bottom-24 right-0 h-6 w-6 rounded-br-2xl border-b-2 border-r-2" />
              </div>
            </div>
          </div>
        )}
        <div
          className={`absolute left-0 top-0 mb-2 w-full p-4 pl-12 ${
            loading ? "moving-div bg-white" : "bg-[#8cd7ff]"
          }`}
        >
          <p className="text-lg" style={{ color: loading ? "black" : "black" }}>
            {loading ? t("camera.scanning") : feedback}
          </p>
        </div>
        <div className="absolute bottom-0 left-0 flex w-full justify-evenly p-4">
          {!loading && isMobile ? (
            <>
              <button
                className="mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-gray-200 p-2"
                onClick={() => fileInputRef.current?.click()}
              >
                <PhotoIcon className="h-7 w-7 text-gray-700" />
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </button>
              <button
                className={`mb-8 flex h-16 w-16 items-center justify-center rounded-full p-2 sm:mb-4 ${
                  isCentralized ? "bg-[#D3FF8C]" : "bg-white"
                }`}
                onClick={captureImage}
              >
                {capturedImage ? (
                  <CheckIcon className="h-10 w-10 text-green-500" />
                ) : (
                  <CameraIcon className="h-7 w-7" />
                )}
              </button>
              <button
                className="mb-8 flex h-12 w-12 items-center justify-center rounded-full bg-pink-200 p-2 sm:mb-4"
                onClick={() => onClose()}
              >
                <XMarkIcon className="h-7 w-7 text-pink-500" />
              </button>
            </>
          ) : (
            <>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileUpload}
              />
            </>
          )}
          {loading && <div className="loader"></div>}
        </div>
      </div>
    </div>
  );
};

export default CameraCapture;
