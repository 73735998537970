import { collection, doc, setDoc, updateDoc } from "firebase/firestore";

import { getCurrentUserEmail } from "../auth";
import { db } from "../firebase";
import { ActivityItem, Event, Order, OrderInfo } from "../pages/OrderPage";

export async function cancelOrderInFirestore(order: Order, database: string) {
  try {
    const orderRef = doc(db, database, order.id);
    const updatedActivity = [
      ...order.activity,
      {
        id: order.activity.length + 1,
        content: "orderStateCancelled",
        date: new Date().toLocaleDateString(),
        dateTime: new Date().toISOString(),
        iconBackground: "bg-red-500",
        handler: getCurrentUserEmail() || "CRITICAL ERROR: NO USER",
      },
    ];

    await updateDoc(orderRef, {
      currentStatus: "Cancelled",
      activity: updatedActivity,
      cancelledAt: new Date().toISOString(),
    });
    window.location.reload(); //Kan hende denne burde ligge sammen med når cancleOrderInFirestore blir kalt
    return true;
  } catch (error) {
    console.error("Error cancelling order:", error);
    return false;
  }
}
