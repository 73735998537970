import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { ActivityItem, Event, Order, OrderInfo } from "../pages/OrderPage";
import ModalTile from "./ModalTile";
import { cancelOrderInFirestore } from "./cancleOrder";

interface PreCancleOrderProps {
  Order: Order;
  Database: string;
}

function PreCancleOrder({ Order, Database }: PreCancleOrderProps) {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="mb-32 w-full border-y border-black">
      <button className="w-full bg-fikseRed" onClick={() => setShowModal(true)}>
        KANSELER ORDERE
      </button>
      <ModalTile open={showModal} onClose={() => setShowModal(false)}>
        Du er i ferd med å KANSELER en ordere. Pls vær sikker på hva du gjør, blir et herk
        å rulle tilbake og notefikere alle involverte parter.
        <button
          className="w-full bg-fikseRed text-white"
          onClick={() => setShowModal2(true)}
        >
          KANSELER ORDERE MED Å TRYKKE HER
        </button>
      </ModalTile>
      <ModalTile
        open={showModal2}
        onClose={() => {
          setShowModal2(false);
          setShowModal(false);
        }}
      >
        <button
          className="w-full bg-fikseRed text-white"
          onClick={() => cancelOrderInFirestore(Order, Database)}
        >
          KANSELER ORDERE MED Å TRYKKE HER
        </button>
      </ModalTile>
    </div>
  );
}

export default PreCancleOrder;
