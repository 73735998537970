export default function Reports() {
  const reportOptions = [
    {
      title: "Revenue",
      description: "Divided by B2B sales, B2C sales, national costumes, etc.",
    },
    {
      title: "Results",
      description: "Revenue after payment to tailors",
    },
    {
      title: "Billing",
      description:
        "Invoices based on what different repairers have fixed - links orders to different repairers.",
    },
    {
      title: "Retail",
      description:
        "Retrieve all orders a brand has made. Distinguishes between stores within the same brand.",
    },
  ];

  return (
    <div className="m-12 mx-8">
      <h2 className="mb-6 text-2xl font-bold text-gray-900">Reports</h2>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {reportOptions.map((report, index) => (
          <div key={index} className="bg-white p-6 shadow">
            <h3 className="mb-2 text-lg font-bold text-gray-900">{report.title}</h3>
            <p className="text-sm text-gray-500">{report.description}</p>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <h3 className="mb-4 text-lg font-bold text-gray-900">View Reports</h3>
        <ul className="space-y-4">
          <li className="bg-white p-4 shadow">
            <h4 className="text-md font-semibold text-gray-900">Revenue</h4>
            <p className="text-sm text-gray-500">
              Details about revenue divided into different areas.
            </p>
          </li>
          <li className="bg-white p-4 shadow">
            <h4 className="text-md font-semibold text-gray-900">Results</h4>
            <p className="text-sm text-gray-500">
              Results after payment to tailors. Separate percentage and agreed price.
            </p>
          </li>
          <li className="bg-white p-4 shadow">
            <h4 className="text-md font-semibold text-gray-900">Billing</h4>
            <p className="text-sm text-gray-500">
              Invoices based on repairs. Link orders to different repairers.
            </p>
          </li>
          <li className="bg-white p-4 shadow">
            <h4 className="text-md font-semibold text-gray-900">Retail</h4>
            <p className="text-sm text-gray-500">
              Orders from the brand with distinctions on which store within the same
              brand.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
