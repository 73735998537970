import React, { useState } from "react";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";

import ModalTile from "../../components/ModalTile";
import { db } from "../../firebase";
import { ReactComponent as ChevronRightIcon } from "../../images/icons-fikse/chevron.svg";
import { Event, OrderInfo } from "../OrderPage";

interface CustomerPromptProps {
  orderInfo: OrderInfo;
  index: number;
  orderID: string;
}
const addOrderEvent = async (
  orderId: string,
  handler: string,
  status: string,
  orderInfoIndex: number
) => {
  try {
    const orderRef = doc(db, "workwearOrders", orderId);

    const newEvent: Event = {
      date: new Date().toISOString(),
      status: status,
      handler: handler,
    };

    // Get the current document using getDoc
    const orderDoc = await getDoc(orderRef);
    const orderData = orderDoc.data();

    if (orderData) {
      const newOrderInfo = [...orderData.orderInfo];

      // Initialize events array if it doesn't exist
      if (!newOrderInfo[orderInfoIndex].event) {
        newOrderInfo[orderInfoIndex].event = [];
      }

      // Add the new event
      newOrderInfo[orderInfoIndex].event.push(newEvent);

      // Update the document
      await updateDoc(orderRef, {
        orderInfo: newOrderInfo,
      });
    }
  } catch (error) {
    console.error("Error adding event:", error);
    throw error;
  }
};

const setNewPrice = async (orderId: string, newPrice: number, orderInfoIndex: number) => {
  try {
    const orderRef = doc(db, "workwearOrders", orderId);

    // Get the current document using getDoc
    const orderDoc = await getDoc(orderRef);
    const orderData = orderDoc.data();

    if (orderData) {
      const newOrderInfo = [...orderData.orderInfo];

      newOrderInfo[orderInfoIndex].price_nok = newPrice;

      // Update the document
      await updateDoc(orderRef, {
        orderInfo: newOrderInfo,
      });
    }
  } catch (error) {
    console.error("Error adding event:", error);
    throw error;
  }
};

const CustomerPrompt: React.FC<CustomerPromptProps> = ({ orderInfo, index, orderID }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const lastEvent = orderInfo?.event?.[orderInfo.event.length - 1];
  console.log("orderInfo?.event");
  console.log(lastEvent?.status);
  return (
    <>
      <div>
        {lastEvent && lastEvent?.status === "deviation" && (
          <>
            <div className="border-b border-black bg-fikseBeige p-4 text-base">
              <span>{t("newPrice")}:</span>
              <span className="ml-1">{lastEvent.newPrice}</span>
            </div>
            <div
              className="flex cursor-pointer border-b border-black bg-fikseGreen p-4 text-center text-base"
              onClick={() => setOpen(true)}
            >
              <button className="border- w-full border-black text-center">
                {t("reponsToDeviaton")}
                {localStorage.getItem("roles")?.includes("tailor") &&
                  t("onBehalfOfCustomer")}{" "}
              </button>

              <ChevronRightIcon aria-hidden="true" />
            </div>

            <ModalTile
              open={open}
              onClose={() => setOpen(false)}
              title={t("reponsToDeviaton")}
            >
              <div className="flex flex-col">
                <div className="p-4 text-base">
                  Det er avvik mellom din bestilling og hva skredder har oppdaget at
                  skadekategori er
                </div>

                <p className="border-y border-black p-4 text-base">
                  <span>{t("newPrice")}:</span>
                  <span className="ml-1">{lastEvent.newPrice}</span>
                </p>

                <p className="border-b border-black p-4 text-base">
                  <span>{t("noteFromTailor")}:</span>
                  <span className="ml-1">{lastEvent.description}</span>
                </p>

                <div
                  className="cursor-pointer border-b border-black bg-fikseGreen p-4 text-center text-base"
                  onClick={async () => {
                    if (lastEvent?.newPrice !== undefined) {
                      await addOrderEvent(orderID, "customer", "customerAproves", index);
                      await setNewPrice(orderID, lastEvent.newPrice, index);
                      window.location.reload();
                    }
                  }}
                >
                  {t("aporveNewPrice")}{" "}
                </div>

                <div
                  className="cursor-pointer bg-fikseRed p-4 text-center text-base"
                  onClick={async () => {
                    await addOrderEvent(
                      orderID,
                      "customer",
                      "cancelledByCustomer",
                      index
                    );
                    window.location.reload();
                  }}
                >
                  {t("cancelOrderButton")}{" "}
                </div>
              </div>
            </ModalTile>
          </>
        )}
      </div>
    </>
  );
};

export default CustomerPrompt;
