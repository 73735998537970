import React from "react";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { DataProvider } from "./DataContext";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <head>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Fikse.co - Det naturlige valget for alt av reparasjoner</title>

      <meta
        name="description"
        content="Fikse.co er det naturlige valget for alt av reparasjoner. Vi gjør prosessen sømløs, rask og kostnadseffektiv for både kunder og merkevarer."
      />

      {/* Open Graph Meta Tags for Social Media Preview */}
      <meta
        property="og:title"
        content="Fikse.co - Det naturlige valget for alt av reparasjoner"
      />
      <meta
        property="og:description"
        content="Vi gjør prosessen sømløs, rask og kostnadseffektiv for både kunder og merkevarer."
      />
      {/*<meta property="og:image" content="https://fikse.co/preview-image.png" />*/}
      <meta property="og:url" content="https://fikse.co" />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Fikse.co - Det naturlige valget for alt av reparasjoner"
      />
      <meta
        name="twitter:description"
        content="Vi gjør prosessen sømløs, rask og kostnadseffektiv for både kunder og merkevarer."
      />

      {/*<meta name="twitter:image" content="https://fikse.co/preview-image.png" />*/}
    </head>
    <BrowserRouter>
      <DataProvider>
        <App />
      </DataProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
