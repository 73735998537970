import { useState } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true);

  return (
    <>
      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Personlig</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            Denne informasjonen er synlig så vær bevisst på hva du deler.
          </p>

          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Fullt navn
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">Andreas Hjertaker</div>
                <button
                  type="button"
                  className="font-semibold text-[#F68302] hover:text-gray-500"
                >
                  Oppdater
                </button>
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                E-post adresse
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">dev@fikse.co</div>
                <button
                  type="button"
                  className="font-semibold text-[#F68302] hover:text-gray-500"
                >
                  Oppdater
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
