import { useEffect, useState } from "react";

import { collection, getDocs } from "firebase/firestore";

import { db } from "../../firebase";
import EditTailorModal from "./EditTailorModal";

// Import modal component

interface Tailor {
  id: string;
  name: string;
  orgNumber: string;
  address: string;
  contactPerson: {
    name: string;
    phone: string;
  };
  cut: {
    agreeBefore: boolean;
    cleaning: number | null;
    repairs: number | null;
  };
}

export default function TailorOverview() {
  const [tailors, setTailors] = useState<Tailor[]>([]);
  const [stats, setStats] = useState({
    totalTailors: 0,
    agreeBefore: 0,
    cleaning: 0,
    repairs: 0,
  });
  const [selectedTailor, setSelectedTailor] = useState<Tailor | null>(null);

  useEffect(() => {
    const fetchTailors = async () => {
      const querySnapshot = await getDocs(collection(db, "tailors"));
      const tailorList: Tailor[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Tailor[];

      setTailors(tailorList);

      const totalTailors = tailorList.length;
      const agreeBefore = tailorList.filter((tailor) => tailor.cut.agreeBefore).length;
      const cleaning = tailorList.filter((tailor) => tailor.cut.cleaning !== null).length;
      const repairs = tailorList.filter((tailor) => tailor.cut.repairs !== null).length;

      setStats({ totalTailors, agreeBefore, cleaning, repairs });
    };

    fetchTailors();
  }, []);

  return (
    <div className="m-12 mx-8">
      <h2 className="mb-4 text-2xl font-bold text-gray-900">Tailors</h2>

      <div className="mb-6 grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-4">
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Total number of tailors</div>
          <div className="text-2xl font-bold text-gray-900">{stats.totalTailors}</div>
        </div>
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Agree price before repair</div>
          <div className="text-2xl font-bold text-gray-900">{stats.agreeBefore}</div>
        </div>
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Cleaning agreements</div>
          <div className="text-2xl font-bold text-gray-900">{stats.cleaning}</div>
        </div>
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Repair agreements</div>
          <div className="text-2xl font-bold text-gray-900">{stats.repairs}</div>
        </div>
      </div>

      <ul role="list" className="grid grid-cols-1 gap-0 lg:grid-cols-4 xl:gap-0">
        {tailors.map((tailor) => (
          <li
            key={tailor.id}
            className="overflow-hidden border border-black border-gray-200 p-6"
          >
            <h3 className="text-lg font-bold text-gray-900">{tailor.name}</h3>
            <p className="text-sm text-gray-500">Org no: {tailor.orgNumber}</p>
            <p className="text-sm text-gray-500">Address: {tailor.address}</p>
            <p className="text-sm text-gray-500">
              Contact person: {tailor.contactPerson.name}
            </p>
            <p className="text-sm text-gray-500">Phone: {tailor.contactPerson.phone}</p>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">
                Agreements with Fikse - Cut:
              </h4>
              {tailor.cut.agreeBefore ? (
                <p className="text-sm text-gray-500">Agreed before repair</p>
              ) : (
                <>
                  {tailor.cut.cleaning !== null && (
                    <p className="text-sm text-gray-500">
                      Cleaning: {tailor.cut.cleaning * 100}%
                    </p>
                  )}
                  {tailor.cut.repairs !== null && (
                    <p className="text-sm text-gray-500">
                      Repairs and adjustments: {tailor.cut.repairs * 100}%
                    </p>
                  )}
                </>
              )}
            </div>
            <button
              className="mt-4 text-sm text-[#8D4304] hover:underline"
              onClick={() => setSelectedTailor(tailor)}
            >
              Edit
            </button>
          </li>
        ))}
      </ul>

      {selectedTailor && (
        <EditTailorModal
          tailor={selectedTailor}
          onClose={() => setSelectedTailor(null)}
          onSave={(updatedTailor) => {
            setTailors((prevTailors) =>
              prevTailors.map((tailor) =>
                tailor.id === updatedTailor.id ? updatedTailor : tailor
              )
            );
            setSelectedTailor(null);
          }}
        />
      )}
    </div>
  );
}
