import { BellIcon, UserCircleIcon, UsersIcon } from "@heroicons/react/24/outline";

import Personal from "../components/profile/Personal";

const secondaryNavigation = [
  { name: "Personlig", href: "#", icon: UserCircleIcon, current: true },
  { name: "Varslinger", href: "#", icon: BellIcon, current: false },
  { name: "Roller", href: "#", icon: UsersIcon, current: false },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  return (
    <>
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
        <h1 className="sr-only">General Settings</h1>

        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul
              role="list"
              className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
            >
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-50 text-[#F68302]"
                        : "text-gray-700 hover:bg-gray-50 hover:text-[#F68302]",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-[#F68302]"
                          : "text-gray-400 group-hover:text-[#F68302]",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <Personal></Personal>
          </div>
        </main>
      </div>
    </>
  );
}
