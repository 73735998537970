import { useEffect } from "react";

import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { square } from "ldrs";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";

import "./App.css";
import { useData } from "./DataContext";
import { synchronizedUser } from "./auth";
import Hero from "./components/Hero";
import NavBar from "./components/NavBar";
import NotFound from "./components/NotFound";
import OrderIDLookup from "./components/OrderIDLookup";
import OrderOverview from "./components/profile/OrderOverview";
import { auth } from "./firebase";
import "./i18n";
// Importer  i18n-oppsettet
import AhlsellPage from "./pages/AhlsellPage";
import B2COrderPage from "./pages/B2COrderPage";
import CameraTestingPage from "./pages/CameraTestingPage";
import DashboardPage from "./pages/DashboardPage";
import Home from "./pages/Home";
import OrderPage from "./pages/OrderPage";
import OsloKommunePage from "./pages/OsloKommunePage";
import Profile from "./pages/ProfilePage";
import TailorPage from "./pages/TailorPage";
import TestMedMicrosoftOslo from "./pages/TestMedMicrosoftOslo";
import Tracking from "./pages/TrackingPage";
import SanityRenderPage from "./sanity/SanityRenderPage";
import usePageViews from "./usePageViews";

// Register the default loader with square
square.register("default-loader");

function App() {
  usePageViews();
  const { setUserId } = useData();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    console.log("App component mounted");
    // Check if the URL contains the email link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const storedEmail = localStorage.getItem("emailForSignIn");

      // If the email is stored, use it to sign in
      if (storedEmail) {
        signInWithEmailLink(auth, storedEmail, window.location.href)
          .then(async (result) => {
            console.log("Successfully signed in:", result.user.email);
            await synchronizedUser(result.user);
            window.location.reload();
          })
          .catch((error) => {
            console.log("Error signing in:", error);
          });
      }
    }
    console.log("LoggedInWithEmail", auth.currentUser);
  }, [searchParams]);

  useEffect(() => {
    const storedUserId = localStorage.getItem("uid");
    if (storedUserId) {
      setUserId(storedUserId);
    }

    const uid = localStorage.getItem("uid");
    const verifiedUid = localStorage.getItem("verifiedUid");
    if (
      uid &&
      uid !== verifiedUid &&
      window.location.pathname !== "/verification" &&
      uid.endsWith("@fikse.co")
    ) {
      //navigate('/verification');  Kommentert ut inntil videre
    }

    const handleKeyDown = (event: any) => {
      console.log(`Key pressed: ${event.code}`); // Legg til logging for å feilsøke

      // For Windows/Linux bruker vi Alt + L
      // For Mac bruker vi Option + KeyL (Option-tasten tilsvarer Alt-tasten på Mac)
      if (event.altKey && event.code === "KeyL") {
        console.log("Language switch detected");
        const newLang = i18n.language === "no" ? "en" : "no";
        i18n.changeLanguage(newLang);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate, setUserId, i18n]);

  return (
    <div className="bg-white">
      {[
        "/profile",
        "/dashboard",
        "/tailor",
        "/ahlsell",
        "/orders",
        "/oslo-kommune",
        "/vintagehome",
      ].includes(window.location.pathname) ? (
        <NavBar />
      ) : null}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/TestMedMicrosoftOslo" element={<TestMedMicrosoftOslo />} />
        <Route path="/a/:OrderIdSlug" element={<OrderIDLookup />} />
        <Route path="/hero" element={<Hero />} />
        <Route path="/tracking" element={<Tracking />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/tailor" element={<TailorPage />} />
        <Route path="/ahlsell" element={<AhlsellPage />} />
        <Route path="/orders" element={<OrderOverview />} />
        <Route path="/oslo-kommune" element={<OsloKommunePage />} />
        <Route path="/camera-testing" element={<CameraTestingPage />} />
        <Route
          path="/orders/b2c/:orderId"
          element={
            <>
              {" "}
              <NavBar />
              <OrderPage database={"b2cOrders"} />
            </>
          }
        />
        <Route
          path="/orders/:orderId"
          element={
            <>
              {" "}
              <NavBar />
              <OrderPage database={"b2cOrders"} />
            </>
          }
        />
        <Route
          path="/orders/workwear/:orderId"
          element={
            <>
              {" "}
              <NavBar />
              <OrderPage database={"workwearOrders"} />
            </>
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/:slug" element={<SanityRenderPage />} />
      </Routes>
    </div>
  );
}

export default App;
