import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  ChartPieIcon,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  ScissorsIcon,
} from "@heroicons/react/24/outline";

import FikseAdminOverview from "../components/admin/FikseAdminOverview";
import OrderOverview from "../components/admin/OrderOverview";
import OrderOverview2 from "../components/admin/OrderOverview2";
import Overview from "../components/admin/Overview";
import Reports from "../components/admin/Reports";
import RetailOverview from "../components/admin/RetailOverview";
import ServiceCategoriesOverview from "../components/admin/ServiceCategoriesOverview";
import AdminDiscountOverview from "../components/admin/discount/AdminDiscountOverview";
import TailorOverview from "../components/admin/TailorOverview";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface NavigationGroup {
  label: string;
  items: {
    id: string;
    name: string;
    href: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    current: boolean;
  }[];
}

const tailorNavigation: NavigationGroup[] = [
  {
    label: "Tailor",
    items: [
      {
        id: "tailor-dashboard",
        name: "Dashboard",
        href: "#",
        icon: ScissorsIcon,
        current: true,
      },
      {
        id: "tailor-orders",
        name: "Orders",
        href: "#",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "tailor-reports",
        name: "Reports",
        href: "#",
        icon: ChartPieIcon,
        current: false,
      },
    ],
  },
];

const workwearNavigation: NavigationGroup[] = [
  {
    label: "WorkWear",
    items: [
      {
        id: "workwear-orders",
        name: "Orders",
        href: "#",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "workwear-reports",
        name: "Future Features",
        href: "#",
        icon: ChartPieIcon,
        current: false,
      },
    ],
  },
];

const adminNavigation: NavigationGroup[] = [
  {
    label: "Admin",
    items: [
      {
        id: "admin-overview",
        name: "Overview",
        href: "#",
        icon: CalendarIcon,
        current: true,
      },
      {
        id: "admin-orders",
        name: "Orders",
        href: "#",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-reports",
        name: "Reports",
        href: "#",
        icon: ChartPieIcon,
        current: false,
      },
      {
        id: "admin-tailor",
        name: "Tailor",
        href: "#",
        icon: ScissorsIcon,
        current: false,
      },
      {
        id: "admin-retail",
        name: "Retail",
        href: "#",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "admin-service-categories",
        name: "Service Categories",
        href: "#",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "workwear-dashboard",
        name: "Dashboard",
        href: "#",
        icon: ScissorsIcon,
        current: true,
      },
      {
        id: "workwear-orders",
        name: "Orders",
        href: "#",
        icon: ClipboardDocumentIcon,
        current: false,
      },
      {
        id: "workwear-reports",
        name: "Reports",
        href: "#",
        icon: ChartPieIcon,
        current: false,
      },
      {
        id: "admin-discount",
        name: "Discount",
        href: "#",
        icon: ChartPieIcon,
        current: false,
      },
    ],
  },
];

export default function DashboardPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentNavItem, setCurrentNavItem] = useState("admin-overview");
  const [navigation, setNavigation] = useState<NavigationGroup[]>([]);

  useEffect(() => {
    const verifiedUid = localStorage.getItem("verifiedUid");
    const isAhlsellUser = verifiedUid?.endsWith("@ahlsell.no");
    const isFikseUser = true
      //verifiedUid?.endsWith("@fikse.co") || verifiedUid?.endsWith("simondar@ntnu.no");

    const isOsloKommuneUser = verifiedUid?.endsWith(
      "mari.guttormsen@uke.oslo.kommune.no "
    );

    if (isAhlsellUser) {
      // Tailor user - show only tailor options
      setNavigation(tailorNavigation);
    }

    if (isFikseUser) {
      // Admin or fikseuser - show all options
      setNavigation(adminNavigation);
    }

    if (isOsloKommuneUser) {
      setNavigation(workwearNavigation);
    }

    if (!verifiedUid || (!isAhlsellUser && !isFikseUser)) {
      //window.location.href = '/'; // Redirect if not authorized
    }
  }, []);

  const handleNavigationChange = (id: string) => {
    setCurrentNavItem(id);
    setNavigation((prevNav) =>
      prevNav.map((group) => ({
        ...group,
        items: group.items.map((item) => ({
          ...item,
          current: item.id === id,
        })),
      }))
    );
  };

  const renderCurrentComponent = () => {
    switch (currentNavItem) {
      case "tailor-dashboard":
        return <div>This is the Tailor Dashboard (empty for now).</div>; // Placeholder until you have a component
      case "tailor-orders":
        return <OrderOverview />;
      case "workwear-orders":
        return <OrderOverview2 />;
      case "tailor-reports":
        return <Reports />;
      case "admin-overview":
        return <Overview />;
      case "admin-orders":
        return <FikseAdminOverview />;
      case "admin-reports":
        return <Reports />;
      case "admin-tailor":
        return <TailorOverview />;
      case "admin-retail":
        return <RetailOverview />;
      case "admin-service-categories":
        return <ServiceCategoriesOverview />;
        case "admin-discount":
          return <AdminDiscountOverview />;
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            {/* Sidebar code omitted for brevity */}
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-stone-50 px-6 pb-4">
            <div className="flex h-40 shrink-0 items-center">
              <img
                className="h-12 w-auto"
                src="https://www.fikse.co/images/logo.svg"
                alt="Your Company"
              />
              <span className="pl-4 text-lg font-bold text-[#8D4304]">Dashboard</span>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col">
                {navigation.map((group) => (
                  <li key={group.label} className="mt-4">
                    <h3 className="text-xs font-semibold uppercase tracking-wider text-gray-500">
                      {group.label}
                    </h3>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      {group.items.map((item, index) => (
                        <li
                          key={item.id}
                          className={classNames(
                            "border-t border-black",
                            index === group.items.length - 1 ? "" : "border-b-0"
                          )}
                        >
                          <a
                            href={item.href}
                            onClick={() => handleNavigationChange(item.id)}
                            className={classNames(
                              item.current
                                ? "bg-[#F1DDC2] text-[#8D4304]"
                                : "text-gray-700 hover:bg-[#F1DDC2] hover:text-[#8D4304]",
                              "group flex gap-x-3 p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-[#8D4304]"
                                  : "text-gray-400 group-hover:text-[#8D4304]",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
                <li className="mt-auto">
                  <Cog6ToothIcon
                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[#8D4304]"
                    aria-hidden="true"
                  />
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-10 flex h-2 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-stone-50 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 mt-16 p-4 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Cog6ToothIcon className="h-10 w-10" aria-hidden="true" />
            </button>
          </div>

          <main>{renderCurrentComponent()}</main>
        </div>
      </div>
    </>
  );
}
