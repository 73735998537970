import { useEffect, useState } from "react";

import DepartmentDropdown from "./DepartmentDropdown";

export default function SelectDepartment() {
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState<string | null>(null);
  const [isOslo, setIsOslo] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    if (uid) {
      const emailPatternOslo =
        /^([a-zA-Z]+)\.([a-zA-Z]+)@([a-zA-Z]+)\.oslo\.kommune\.no$/;
      const matchOslo = uid.match(emailPatternOslo);
      if (matchOslo) {
        setIsOslo(true);
        setFirstName(matchOslo[1]);
        setLastName(matchOslo[2]);
        setDepartment(matchOslo[3]);
      } else {
        setIsOslo(false);
        setFirstName(null);
        setLastName(null);
        setDepartment(null);
      }
      if (uid.endsWith("@ahlsell.no")) {
        window.location.href = "/ahlsell";
      }
      if (uid.endsWith("@fikse.co")) {
        window.location.href = "/admin";
      }
    }
  }, [email]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleConfirmClick = () => {
    if (email) {
      localStorage.setItem("uid", email);
      if (email.endsWith("@ahlsell.no")) {
        window.location.href = "/ahlsell";
      } else {
        window.location.reload();
      }
    } else {
      alert("Vennligst skriv inn en gyldig e-postadresse.");
    }
  };

  if (!localStorage.getItem("uid")) {
    return (
      <div className="relative isolate overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20">
          <div className="max-w-2xl lg:flex-auto">
            <h1 className="max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              En enklere måte å reparere klær
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Nå lanserer vi en mer brukervennlig tjeneste for reparasjon av arbeidstøy i
              Oslo Kommune.
            </p>
            <div className="mx-auto mt-8 max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border p-4 lg:max-w-none">
              <div className="max-w-xl lg:max-w-lg">
                <h2 className="text-3xl font-bold tracking-tight text-[#8cd7ff] sm:text-4xl">
                  Skriv inn din e-postadresse
                </h2>
                <p className="mt-4 text-lg leading-8 text-gray-700">
                  Vi vil plassere deg i rett etat, og sende oppdateringer på e-post.
                </p>
                <div className="mt-6 flex max-w-md gap-x-4">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="min-w-0 flex-auto border-0 bg-white/5 px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"
                    placeholder="fornavn.etternavn@etat.oslo.kommune.no"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <button
                    type="button"
                    className="flex-none bg-[#8cd7ff] px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                    onClick={handleConfirmClick}
                  >
                    Bekreft
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          {isOslo && (
            <p className="text-base font-semibold leading-7 text-[#8cd7ff]">
              Hei{" "}
              {firstName && firstName[0].toUpperCase() + firstName.slice(1).toLowerCase()}{" "}
              {lastName && lastName[0].toUpperCase() + lastName.slice(1).toLowerCase()}
            </p>
          )}
          {isOslo && (
            <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Du er straks klar
            </h2>
          )}
          {isOslo ? (
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Vi har registrert at du jobber i Oslo Kommune. Vennligst verifiser
              opplysningene nedenfor før du bestiller din første fiks!
            </p>
          ) : (
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Vi har ikke registrert at du jobber i Oslo Kommune. Vennligst logg ut og
              benytt en mail i formatet fornavn.etternavn@etat.oslo.kommune.no dersom du
              tilgang på dette.
              <a
                className="m-10 cursor-pointer font-bold"
                onClick={() => {
                  localStorage.setItem("uid", "");
                  localStorage.setItem("department", "");
                  window.location.href = "/";
                }}
              >
                Prøv igjen
              </a>
            </p>
          )}
          {isOslo && (
            <>
              <DepartmentDropdown department={department} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
