import React, { useState } from "react";

const Button = ({ label, isActive, onClick }: { label: string; isActive: boolean; onClick: () => void }) => (
  <>
    {label !== "space" ? (
      <button
        className={`border border-black px-2 py-3 ${isActive ? "bg-fikseBlue font-semibold" : ""}`}
        onClick={onClick}
      >
        {label}
      </button>
    ) : (
      <span className="border border-black bg-fikseBeige px-4 py-2"></span>
    )}
  </>
);

const buttons = [
  "Alle scannet",
  "space",
  "Alle",
  "Frist idag",
  "Frist snart",
  "Frist kommer seg",
  "space",
  "Fullført",
];

const TailorPage: React.FC = () => {
  const [activeButton, setActiveButton] = useState<string | null>("Alle");

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="font-sans">
      <header className="flex items-center justify-between border-b border-gray-300 bg-gray-100 p-4">
        <div className="flex items-center">
          <img src="https://www.fikse.co/images/logo.svg" alt="Fikse Logo" className="h-12 w-12" />{" "}
          {/* Replace with actual logo URL */}
          <div className="ml-3">
            <h1 className="text-2xl font-bold">Fikse</h1>
            <p className="text-sm">Bedriftskunde</p>
          </div>
        </div>
        <nav className="flex">
          <button className="border border-black bg-blue-200 px-4 py-2 font-semibold">Ordreoversikt</button>
          <button className="border border-black px-4 py-2">Analytisk</button>
          <button className="border border-black px-4 py-2">Faktura</button>
          <button className="border border-black px-4 py-2">Meldinger</button>
        </nav>
        <button className="text-2xl">☰</button>
      </header>

      <main>
        <div className="border-collapse-fix grid-cols-auto grid grid-cols-8">
          {buttons.map((label) => (
            <Button
              key={label}
              label={label}
              isActive={activeButton === label}
              onClick={() => handleButtonClick(label)}
            />
          ))}
        </div>
        <div className="bg-blue-200 px-4 py-9 sm:grid-cols-2"></div>
      </main>
    </div>
  );
};

export default TailorPage;
