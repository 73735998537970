import { Fragment, useRef } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

interface ContactUsModalProps {
  open: boolean;
  onClose: () => void;
}

const ContactUsModal: React.FC<ContactUsModalProps> = ({ open, onClose }) => {
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation();
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        {/* Background overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Modal panel */}
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-4 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="ease-in duration-200"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="translate-y-4 opacity-0"
          >
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
              {/* Modal header */}
              <Dialog.Title
                as="h3"
                className="relative p-4 text-xl leading-6 text-gray-900"
              >
                <div className="absolute right-0 top-0 p-4">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={onClose}
                  >
                    <span className="sr-only">{t("close")}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </Dialog.Title>

              <p className="whitespace-normal break-words px-8 pb-8 text-base">
                {"Spørsmål? Ta kontakt på hei@fikse.co eller ring +47 484 50 390"}
              </p>

              {/* Modal content */}
              {/* <div>
                <p className="whitespace-normal break-words pl-4 text-base text-gray-500">
                  {t("contactUs.contactUsMessage")}
                </p>
              </div> */}

              {/* Contact form */}
              {/* <div>
                <form>
                  <div>
                    <label
                      htmlFor="names"
                      className="block p-4 pt-6 text-base font-medium text-gray-700"
                    >
                      {t("contactUs.name")}
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="w-full border-b border-t border-black p-4"
                      placeholder={t("contactUs.nameInpit")}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block p-4 pt-6 text-base font-medium text-gray-700"
                    >
                      {t("contactUs.email")}
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="w-full border-b border-t border-black p-4"
                      placeholder={t("contactUs.emailInput")}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="message"
                      className="block p-4 pt-6 text-base font-medium text-gray-700"
                    >
                      {t("contactUs.message")}
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      className="w-full border-t border-black p-4"
                      placeholder={t("contactUs.messageInput")}
                    />
                  </div>
                  <div className="flex w-full justify-center">
                    <button
                      type="submit"
                      className="w-full border-t border-black bg-fikseGreen p-4 text-center"
                    >
                      {t("send")}
                    </button>
                  </div>
                </form>
              </div>*/}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ContactUsModal;
