// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, OAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Legg til denne linjen

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDLtQUa-vpahe_L9wihla09OfTFmoSo94w",
  authDomain: "fiksefirstapp.firebaseapp.com",
  projectId: "fiksefirstapp",
  storageBucket: "fiksefirstapp.appspot.com",
  messagingSenderId: "1036858581379",
  appId: "1:1036858581379:web:61cda6ab68f35d32b016b6",
  measurementId: "G-KYK57EB4GQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Legg til denne linjen

export const googleProvider = new GoogleAuthProvider();
export const microsoftProvider = new OAuthProvider("microsoft.com");

export { db, app, auth, storage };
