import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";

const db = getFirestore();

export async function retailAdressesFromRetailUUID(
  retailUUIDs: string[]
): Promise<string[]> {
  const addresses: string[] = [];
  const retailRef = collection(db, "retail");
  const chunkSize = 10;

  for (let i = 0; i < retailUUIDs.length; i += chunkSize) {
    const chunk = retailUUIDs.slice(i, i + chunkSize);
    const q = query(retailRef, where("__name__", "in", chunk));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (Array.isArray(data.addresses)) {
        addresses.push(...data.addresses);
      }
    });
  }

  return addresses;
}
