import { Fragment, useEffect, useState } from "react";

import { Listbox, Transition } from "@headlessui/react";
import { CheckCircleIcon, CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { collection, getDocs } from "firebase/firestore";

import { db } from "../firebase";

interface DepartmentDropdownProps {
  department: string | null;
}

interface Department {
  id: string;
  name: string;
  defaultAddress: string;
}

export default function DepartmentDropdown({ department }: DepartmentDropdownProps) {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [selected, setSelected] = useState<Department | null>(null);
  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");
  const [address, setAddress] = useState(localStorage.getItem("address") || "");

  const handleConfirmDepartmentClick = () => {
    if (selected) {
      localStorage.setItem("department", selected.id + " - " + selected.name);
      localStorage.setItem("phone", phone);
      localStorage.setItem("address", address);
      window.location.reload();
    } else {
      alert("Vennligst velg en gyldig avdeling.");
    }
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      const querySnapshot = await getDocs(collection(db, "municipalityDepartments"));
      const departmentsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name, // assuming the document has a 'name' field
        defaultAddress: doc.data().defaultAddress,
      }));
      setDepartments(departmentsData);
    };

    fetchDepartments();
  }, []);

  useEffect(() => {
    if (department) {
      const foundDepartment = departments.find((d) => d.id === department.toUpperCase());
      if (foundDepartment) {
        setSelected(foundDepartment);
        if (!localStorage.getItem("address")) {
          setAddress(foundDepartment.defaultAddress);
        }
      }
    }
  }, [department, departments]);

  useEffect(() => {
    if (selected) {
      if (!localStorage.getItem("address")) {
        setAddress(selected.defaultAddress);
      }
    }
  }, [selected]);

  return (
    <div className="my-8 w-80">
      <label className="text-gray-500">Din avdeling er</label>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default bg-white py-2 pl-3 pr-10 text-left font-bold text-[#8cd7ff] shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
            <span className="block truncate">
              {selected ? `${selected.id} - ${selected.name}` : "Velg en etat"}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {departments.map((department) => (
                <Listbox.Option
                  key={department.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={department}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? "font-medium" : "font-normal"}`}
                      >
                        {`${department.id} - ${department.name}`}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      <div className="mt-4">
        <label className="block text-gray-700">Telefonnummer</label>
        <input
          type="text"
          className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="mt-4">
        <label className="block text-gray-700">Returadresse</label>
        <input
          type="text"
          className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      <button
        type="button"
        className="mt-4 inline-flex items-center gap-x-2 bg-[#8cd7ff] px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={handleConfirmDepartmentClick}
      >
        Bekreft
        <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  );
}
