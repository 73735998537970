// src/sanityClient.ts
import { createClient } from "@sanity/client";
import { type SanityClient } from "@sanity/client";

// @ts-ignore
const client = createClient({
  projectId: "w93szyy4", // Replace with your actual project ID
  dataset: "production",
  apiVersion: "2023-10-20", // Use the current date
  useCdn: true, // `false` if you want to ensure fresh data
}) as SanityClient;

export default client;
