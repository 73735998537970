import {
  CameraIcon,
  CheckIcon,
  EnvelopeIcon,
  PaperAirplaneIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

export default function Instructions() {
  return (
    <div className="border border-black p-10">
      <h2 className="text-xl font-semibold text-gray-900">
        Husk at klærne må være vasket og rene før du sender bestilling!
      </h2>
      <h2 className="mt-4 text-xl font-semibold text-gray-900">Slik fikser du:</h2>
      <ul className="mt-4 list-decimal pl-5">
        <li className="mt-4 grid grid-cols-3 items-center gap-4">
          <div className="col-span-2 my-4">
            <strong>1. Logg inn her</strong>
            <br />
            Bruk din ansatt-epost
          </div>
          <UserIcon className="h-10 w-10 text-white hover:text-[#8cd7ff]" />
        </li>
        <li className="mt-4 grid grid-cols-3 items-center gap-4">
          <div className="col-span-2 my-4">
            <strong>2. Ta bilde av det du skal fikse</strong>
            <br />
            Sjekk at det er riktig, eller velg manuelt. Husk å legge til beskrivelse, for
            eksempel farge på glidelås.
          </div>
          <CameraIcon className="h-10 w-10 text-white hover:text-[#8cd7ff]" />
        </li>
        <li className="mt-4 grid grid-cols-3 items-center gap-4">
          <div className="col-span-2 my-4">
            <strong>3. Fullfør bestillingen</strong>
            <br />
            Legg til produkter og leveringsinformasjon
          </div>
          <CheckIcon className="h-10 w-10 text-white hover:text-[#8cd7ff]" />
        </li>
        <li className="mt-4 grid grid-cols-3 items-center gap-4">
          <div className="col-span-2 my-4">
            <strong>4. Sjekk epost</strong>
            <br />
            Følg instruksjonene du har fått på epost.
          </div>
          <EnvelopeIcon className="h-10 w-10 text-white hover:text-[#8cd7ff]" />
        </li>
        <li className="mt-4 grid grid-cols-3 items-center gap-4">
          <div className="col-span-2 my-4">
            <strong>5. Send bestilling</strong>
            <br />
            Skriv ordrenummeret på pakken eller print ut bestilling du fikk på epost.
          </div>
          <PaperAirplaneIcon className="h-10 w-10 text-white hover:text-[#8cd7ff]" />
        </li>
      </ul>
    </div>
  );
}
