import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

interface EditDiscountModalProps {
  discount: {
    id: string;
    name: string;
    value: number;
    count: number;
    used: number;
    timed: boolean;
    validFrom: { seconds: number; nanoseconds: number } | null;
    validTo: { seconds: number; nanoseconds: number } | null;
  };
  onClose: () => void;
  onSave: (updatedDiscount: any) => void; // Callback for parent state
}

export default function EditDiscountModal({
  discount,
  onClose,
  onSave,
}: EditDiscountModalProps) {
  const [updatedDiscount, setUpdatedDiscount] = useState({
    ...discount,
    validFrom: discount.validFrom
      ? new Date(discount.validFrom.seconds * 1000)
      : null,
    validTo: discount.validTo
      ? new Date(discount.validTo.seconds * 1000)
      : null,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setUpdatedDiscount((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDateChange = (date: Date | null, field: "validFrom" | "validTo") => {
    setUpdatedDiscount((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  const handleSave = async () => {
    try {
      // Prepare Firestore-compatible data
      const preparedDiscount = {
        ...updatedDiscount,
        validFrom: updatedDiscount.validFrom
          ? {
              seconds: Math.floor(updatedDiscount.validFrom.getTime() / 1000),
              nanoseconds: 0,
            }
          : null,
        validTo: updatedDiscount.validTo
          ? {
              seconds: Math.floor(updatedDiscount.validTo.getTime() / 1000),
              nanoseconds: 0,
            }
          : null,
      };

      // Update Firestore document
      const discountRef = doc(db, "discount", updatedDiscount.id);
      await updateDoc(discountRef, preparedDiscount);

      // Pass updated data back to parent
      onSave(preparedDiscount);

      // Close modal
      onClose();
    } catch (error) {
      console.error("Error updating discount:", error);
      alert("An error occurred while updating the discount.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md p-6 bg-white rounded-md shadow-lg">
        <h2 className="text-lg font-bold text-gray-900">Edit Discount</h2>
        <div className="mt-4 space-y-4">
          {/* Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={updatedDiscount.name}
              onChange={handleInputChange}
              className="w-full rounded-md border border-gray-300 px-4 py-2"
            />
          </div>

          {/* Value */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Value</label>
            <input
              type="number"
              name="value"
              value={updatedDiscount.value}
              onChange={handleInputChange}
              className="w-full rounded-md border border-gray-300 px-4 py-2"
            />
          </div>

          {/* Count */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Count</label>
            <input
              type="number"
              name="count"
              value={updatedDiscount.count}
              onChange={handleInputChange}
              className="w-full rounded-md border border-gray-300 px-4 py-2"
            />
          </div>

          {/* Timed */}
          <div className="flex items-center space-x-4">
            <label className="block text-sm font-medium text-gray-700">
              <input
                type="checkbox"
                name="timed"
                checked={updatedDiscount.timed}
                onChange={handleInputChange}
                className="mr-2"
              />
              Timed Validity
            </label>
          </div>

          {/* Valid From */}
          {updatedDiscount.timed && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Valid From
              </label>
              <DatePicker
                selected={updatedDiscount.validFrom}
                onChange={(date) => handleDateChange(date, "validFrom")}
                className="w-full rounded-md border border-gray-300 px-4 py-2"
              />
            </div>
          )}

          {/* Valid To */}
          {updatedDiscount.timed && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Valid To
              </label>
              <DatePicker
                selected={updatedDiscount.validTo}
                onChange={(date) => handleDateChange(date, "validTo")}
                className="w-full rounded-md border border-gray-300 px-4 py-2"
              />
            </div>
          )}
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}