export default function Overview() {
  return (
    <div className="bg-white">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Coming soon ...{" "}
              <a href="#" className="font-semibold text-[#8D4304]">
                <span className="absolute inset-0" aria-hidden="true" />
                Contact development <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              As an administrator, you can keep track of operations.
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Monitor all orders and users involved through various roles.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
