// Importer nødvendige Firebase-funksjoner
import { useEffect, useState } from "react";

import { Timestamp, addDoc, collection, getDocs } from "firebase/firestore";

import { db } from "../../firebase";
import { EditRetailModal } from "./EditRetailModal";

// Importer modal komponenten

interface Retail {
  id: string;
  name: string;
  orgNumber: string;
  addresses: string[];
  contactPerson: {
    name: string;
    phone: string;
    email: string;
  };
}

let newCategory = [
  {
    fixId: "000053",
    group: "Worn heel cap",
    price: 349,
    problem: "Worn heel cap",
    subcategory: "Women's",
    type: "Heel cap",
    work: "Replace heel cap (women's)",
  },
  {
    fixId: "000054",
    group: "Worn heel cap",
    price: 449,
    problem: "Worn heel cap",
    subcategory: "Men's",
    type: "Heel cap",
    work: "Replace heel cap (men's)",
  },
  {
    fixId: "000055",
    group: "Loose heel cap",
    price: 149,
    problem: "Loose heel cap",
    subcategory: "Women's",
    type: "Heel cap",
    work: "Glue heel cap (women's)",
  },
  {
    fixId: "000056",
    group: "Loose heel cap",
    price: 299,
    problem: "Loose heel cap",
    subcategory: "Men's",
    type: "Heel cap",
    work: "Glue heel cap (men's)",
  },
  {
    fixId: "000057",
    group: "Worn sole",
    price: 749,
    problem: "Worn sole",
    subcategory: "Resole and heel cap",
    type: "Sole",
    work: "Replace sole (resole and heel cap)",
  },
  {
    fixId: "000058",
    group: "Worn sole",
    price: 599,
    problem: "Worn sole",
    subcategory: "Resole",
    type: "Sole",
    work: "Replace sole (resole)",
  },
  {
    fixId: "000059",
    group: "Loose sole",
    price: 249,
    problem: "Loose sole",
    subcategory: "Resole",
    type: "Sole",
    work: "Glue sole (resole)",
  },
  {
    fixId: "000060",
    group: "Broken zipper",
    price: 499,
    problem: "Broken zipper",
    subcategory: "Short zipper",
    type: "Zipper",
    work: "Replace zipper (short)",
  },
  {
    fixId: "000061",
    group: "Broken zipper",
    price: 699,
    problem: "Broken zipper",
    subcategory: "Long zipper",
    type: "Zipper",
    work: "Replace zipper (long)",
  },
  {
    fixId: "000062",
    group: "Tight shoe",
    price: 349,
    problem: "Tight shoe",
    subcategory: "Foot",
    type: "Leather shoe",
    work: "Stretch leather shoe (foot)",
  },
  {
    fixId: "000063",
    group: "Tight shoe",
    price: 449,
    problem: "Tight shoe",
    subcategory: "Leg",
    type: "Leather shoe",
    work: "Stretch leather shoe (leg)",
  },
  {
    fixId: "000064",
    group: "Hole or broken seam",
    price: 299,
    problem: "Hole or broken seam",
    subcategory: "Small damage",
    type: "Hole/seam",
    work: "Repair hole or seam (small)",
  },
  {
    fixId: "000065",
    group: "Hole or broken seam",
    price: 399,
    problem: "Hole or broken seam",
    subcategory: "Large damage",
    type: "Hole/seam",
    work: "Repair hole or seam (large)",
  },
  {
    fixId: "000066",
    group: "Dirty shoe",
    price: 349,
    problem: "Dirty shoe",
    subcategory: "One shoe",
    type: "Cleaning and polishing",
    work: "Cleaning and polishing (one shoe)",
  },
  {
    fixId: "000067",
    group: "Dirty shoe",
    price: 599,
    problem: "Dirty shoe",
    subcategory: "Pair",
    type: "Cleaning and polishing",
    work: "Cleaning and polishing (pair)",
  },
];

export default function RetailOverview() {
  const [retailers, setRetailers] = useState<Retail[]>([]);
  const [stats, setStats] = useState({
    totalRetailers: 0,
    multipleAddresses: 0,
  });
  const [selectedRetailer, setSelectedRetailer] = useState<Retail | null>(null);

  useEffect(() => {
    const fetchRetailers = async () => {
      const querySnapshot = await getDocs(collection(db, "retail"));
      const retailerList: Retail[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Retail[];

      setRetailers(retailerList);

      const totalRetailers = retailerList.length;
      const multipleAddresses = retailerList.filter(
        (retailer) => retailer.addresses.length > 1
      ).length;

      setStats({ totalRetailers, multipleAddresses });
    };

    fetchRetailers();
  }, []);

  const handleAddCategory = async () => {
    const categoryName = prompt("Enter the name for the new category:");
    if (categoryName) {
      try {
        await addDoc(collection(db, "serviceCategories"), {
          name: categoryName,
          categories: newCategory,
          createdAt: Timestamp.now(),
        });
        alert("Category added successfully!");
      } catch (error) {
        console.error("Error adding category: ", error);
        alert("Failed to add category. Please try again.");
      }
    } else {
      alert("Category name is required.");
    }
  };

  return (
    <div className="m-12 mx-8">
      <h2 className="mb-4 text-2xl font-bold text-gray-900">Retailers</h2>

      <div className="mb-6 grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-4">
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Total number of retailers</div>
          <div className="text-2xl font-bold text-gray-900">{stats.totalRetailers}</div>
        </div>
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Retailers with multiple addresses</div>
          <div className="text-2xl font-bold text-gray-900">
            {stats.multipleAddresses}
          </div>
        </div>
      </div>

      <ul role="list" className="grid grid-cols-1 gap-0 lg:grid-cols-4 xl:gap-0">
        {retailers.map((retailer) => (
          <li
            key={retailer.id}
            className="overflow-hidden border border-black border-gray-200 p-6"
          >
            <h3 className="text-lg font-bold text-gray-900">{retailer.name}</h3>
            <p className="text-sm text-gray-500">Org no: {retailer.orgNumber}</p>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">Addresses:</h4>
              {retailer.addresses.map((address, index) => (
                <p key={index} className="text-sm text-gray-500">
                  {address}
                </p>
              ))}
            </div>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">Contact Person:</h4>
              <p className="text-sm text-gray-500">Name: {retailer.contactPerson.name}</p>
              <p className="text-sm text-gray-500">
                Phone: {retailer.contactPerson.phone}
              </p>
              <p className="text-sm text-gray-500">
                Email: {retailer.contactPerson.email}
              </p>
            </div>
            <button
              className="mt-4 text-sm text-indigo-600 hover:underline"
              onClick={() => setSelectedRetailer(retailer)}
            >
              Edit
            </button>
          </li>
        ))}
      </ul>

      {selectedRetailer && (
        <EditRetailModal
          retail={selectedRetailer}
          onClose={() => setSelectedRetailer(null)}
          onSave={(updatedRetailer) => {
            setRetailers((prevRetailers) =>
              prevRetailers.map((retailer) =>
                retailer.id === updatedRetailer.id ? updatedRetailer : retailer
              )
            );
            setSelectedRetailer(null);
          }}
        />
      )}

      {/* Ny knapp for å legge til kategori */}
      <button
        className="mt-8 rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
        onClick={handleAddCategory}
      >
        Add New Category
      </button>
    </div>
  );
}
