import React, { useEffect, useState } from "react";

import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import QRCode from "qrcode";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getCurrentUserEmail } from "../../auth";
import { db, storage } from "../../firebase";
import { ReactComponent as FikseCatLady } from "../../images/Illustration/FikseFigures/footer-woman-cat.svg";
import { ReactComponent as CheckIcon } from "../../images/icons-fikse/checkmark.svg";

interface OrderInfo {
  id: string;
  item: string;
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  work: string;
  placeholder: string;
  imageUrl: string;
  customerComment: string;
  count: number;
}

interface ContactInfo {
  department: string;
  name: string;
  phone: string;
  address?: string;
}

interface ApiResponse {
  firstName: string;
  middleName?: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
}

interface CheckoutWorkwearProps {
  garments: OrderInfo[];
  contactInfo: ContactInfo;
  onClose: () => void;
  onBackToCart: () => void;
}

const CheckoutWorkwear: React.FC<CheckoutWorkwearProps> = ({
  garments,
  contactInfo,
  onClose,
  onBackToCart,
}) => {
  console.log("garments", garments);
  const [phoneNumber, setPhoneNumber] = useState(contactInfo.phone || "");
  const [customerRef, setCustomerRef] = useState("");
  const [resourceNumber, setResourceNumber] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryDepartment, setDeliveryDepartment] = useState("");
  const [retrieveAddress, setRetrieveAddress] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [lookupData, setLookupData] = useState<ApiResponse | null>(null);
  const [lookupStatus, setLookupStatus] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [uploading, setUploading] = useState(false);
  const [theyDeliver, setTheyDeliver] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const emailFromAuth = getCurrentUserEmail();

  useEffect(() => {
    console.log("garments", garments);
    setIsFormValid(
      phoneNumber !== "" &&
        fullName !== "" &&
        garments.length > 0 &&
        resourceNumber !== "" &&
        (theyDeliver || (deliveryAddress !== "" && deliveryDepartment !== ""))
    );
  }, [
    phoneNumber,
    garments,
    fullName,
    theyDeliver,
    resourceNumber,
    deliveryAddress,
    deliveryDepartment,
  ]);

  useEffect(() => {
    console.log("phoneNumber", phoneNumber);
    if (phoneNumber.length === 8) {
      handleLookupClick();
    } else {
      setLookupData(null);
    }
  }, [phoneNumber]);

  const functions = getFunctions(undefined, "europe-west1");
  const fetchPhoneNumberData = httpsCallable<{ phone: string }, ApiResponse>(
    functions,
    "fetchPhoneNumberData"
  );

  const handleLookupClick = async () => {
    if (phoneNumber.length === 8) {
      setLookupStatus(t("checkoutWorkwear.lookupStatus"));
      try {
        const response = await fetchPhoneNumberData({ phone: phoneNumber });

        if (response.data && Object.keys(response.data).length > 0) {
          const data: ApiResponse = response.data;
          setLookupData(data);
          setFullName(
            `${data.firstName}${
              data.middleName ? " " + data.middleName : ""
            }${data.lastName ? " " + data.lastName : ""}`
          );
          setAddress(`${data.address}, ${data.postalCode} ${data.city}`);
          setLookupStatus(t("checkoutWorkwear.lookupSuccess"));
        } else {
          setLookupData(null);
          setLookupStatus(t("checkoutWorkwear.noInfoFound"));
        }
      } catch (error) {
        console.error("Error fetching lookup data: ", error);
        setLookupStatus(t("checkoutWorkwear.lookupFailed"));
      }
    } else {
      setLookupStatus(t("checkoutWorkwear.phoneNumberError"));
    }
  };

  const calculateTotalPrice = (orderInfo: OrderInfo[]) => {
    return orderInfo.reduce((total, item) => total + item.price_nok, 0);
  };

  const uploadImagesAndGetUrls = async (orderInfo: OrderInfo[]) => {
    const uploadPromises = orderInfo.map(async (item, index) => {
      if (item.imageUrl.startsWith("data:image")) {
        const storageRef = ref(storage, `workwearOrders/${Date.now()}_${index}.jpg`);
        await uploadString(storageRef, item.imageUrl, "data_url");
        const url = await getDownloadURL(storageRef);
        return { ...item, imageUrl: url };
      }
      return item;
    });

    return Promise.all(uploadPromises);
  };

  const generateCustomOrderId = () => {
    const letters = Array.from({ length: 5 }, () =>
      String.fromCharCode(65 + Math.floor(Math.random() * 26))
    ).join("");

    setCustomerRef(letters);

    const numbers = Array.from({ length: 6 }, () => Math.floor(Math.random() * 10)).join(
      ""
    );

    return `${letters}${numbers}WWE`;
  };

  const sendOrderConfirmationEmail = async (orderId: string, orderData: any) => {
    const sendEmail = httpsCallable<
      {
        orderId: string;
        email: string;
        department: string;
        orderInfo: OrderInfo[];
        contactInfo: any;
        resourceNumber: any;
        deliveryAddress: any;
        deliveryDepartment: any;
      },
      void
    >(functions, "sendOrderConfirmationEmail");

    try {
      await sendEmail({
        orderId: orderId,
        email: emailFromAuth,
        department: contactInfo.department,
        orderInfo: orderData.orderInfo,
        contactInfo: orderData.contactInfo,
        resourceNumber: orderData.resourceNumber,
        deliveryAddress: orderData.deliveryAddress,
        deliveryDepartment: orderData.deliveryDepartment,
      });
    } catch (error) {
      console.error(t("checkoutWorkwear.emailError"), error);
    }
  };

  const handlePaymentClick = async () => {
    if (isFormValid) {
      try {
        setUploading(true);

        const sanitizedOrderInfo = await uploadImagesAndGetUrls(garments);

        const activity = [
          {
            timestamp: new Date().toISOString(),
            message: t("checkoutWorkwear.orderCreated"), //Vet ikke om denne kan fjernes, er en avhenig av noe annet kanskje, idk ikke testet
            content: "orderStateCreated", //Hvis endring i denne sjekk orderpage -> orderState array
          },
        ];

        const customOrderId = generateCustomOrderId();
        const orderData = {
          customerRef: customerRef,
          email: emailFromAuth,
          orderInfo: sanitizedOrderInfo,
          contactInfo: {
            ...contactInfo,
            phone: phoneNumber,
            name: fullName,
            address,
            department: localStorage.getItem("uid")?.endsWith("osloskolen.no")
              ? "Osloskolen"
              : contactInfo.department,
          },
          resourceNumber,
          date: new Date().toISOString(),
          totalPrice: `${calculateTotalPrice(sanitizedOrderInfo)} kr`,
          ...(theyDeliver ? {} : { deliveryAddress, deliveryDepartment }),
          retrieveAddress,
          activity,
          theyDeliver: theyDeliver,
        };

        const docRef = doc(db, "workwearOrders", customOrderId);
        await setDoc(docRef, orderData);

        const qrData = `https://fiksefirstapp.web.app/orders/workwear/${customOrderId}#scanned`;
        const qrCodeDataUrl = await QRCode.toDataURL(qrData);

        const qrCodeStorageRef = ref(storage, `qrcodes/${customOrderId}.png`);
        await uploadString(qrCodeStorageRef, qrCodeDataUrl, "data_url");
        const qrCodeUrl = await getDownloadURL(qrCodeStorageRef);

        await updateDoc(docRef, {
          qrCodeUrl: qrCodeUrl,
        });

        const smsMessage =
          t("checkoutWorkwear.orderConfirmation.Hi") +
          ` ${fullName.split(" ")[0]}!\n\n` +
          t("checkoutWorkwear.orderConfirmation.title") +
          `${customOrderId.substring(0, 5)}\n\n` +
          t("checkoutWorkwear.orderConfirmation.markPackage") +
          ` ${deliveryDepartment}.` +
          t("checkoutWorkwear.orderConfirmation.rememberToMark") +
          `\nhttps://oslo.kommune.fikse.co/orders/workwear/${customOrderId}\n\n` +
          t("checkoutWorkwear.orderConfirmation.thanks");

        await setDoc(doc(collection(db, "messages"), customOrderId), {
          to: "+47" + phoneNumber,
          from: "Fikse",
          body: smsMessage,
        });

        await sendOrderConfirmationEmail(customOrderId, orderData);

        localStorage.setItem("activeOrder", `workwear/${customOrderId}`);
        navigate(`/orders/workwear/${customOrderId}#newOrder`);
        onClose();
      } catch (error) {
        console.error("Error saving order: ", error);
        alert(t("checkoutWorkwear.errorSavingOrder"));
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div className="flex h-full items-center justify-center bg-black bg-opacity-50">
      <div className="flex max-h-[calc(100vh)] w-full max-w-lg flex-col overflow-auto bg-white shadow-xl">
        <div className="z-10 flex items-center justify-between border-b border-black bg-white">
          <div className="flex items-center">
            <button
              type="button"
              className="p-4 text-2xl text-gray-500"
              onClick={onBackToCart}
            >
              &larr;
            </button>
            <h1 className="py-2 text-2xl">
              {t("checkoutWorkwear.order")} -{" "}
              {localStorage.getItem("uid")?.endsWith("osloskolen.no")
                ? "Osloskolen"
                : contactInfo.department}
            </h1>
          </div>
          <button type="button" className="p-4 text-2xl text-gray-500" onClick={onClose}>
            ×
          </button>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="border-b border-black">
            <label className="mt-2 block p-4 text-sm font-medium text-gray-700">
              {t("checkoutWorkwear.updateMethod")}
            </label>
            <input
              type="number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder={t("checkoutWorkwear.phoneNumberPlaceholder")}
              className="text-md block w-full rounded-none border-t border-black bg-[#f7f6eb] p-4"
            />
          </div>

          {lookupStatus && (
            <div className="border-b border-black">
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder={t("checkoutWorkwear.fullNamePlaceholder")}
                className="text-md block w-full rounded-none border-b border-black bg-[#f7f6eb] p-4"
              />
              <p className="text-md block w-full rounded-none border-black bg-fikseBeige p-4">
                {emailFromAuth}
              </p>
            </div>
          )}

          <div className="border-b border-black">
            <label className="mt-2 block p-4 text-sm font-medium text-gray-700">
              {t("checkoutWorkwear.resourceNumber")}
            </label>
            <input
              value={resourceNumber}
              onChange={(e) => setResourceNumber(e.target.value)}
              placeholder={t("checkoutWorkwear.resourceNumberPlaceholder")}
              className="text-md block w-full rounded-none border-t border-black bg-[#f7f6eb] p-4"
            />
          </div>

          <div
            className={`text-md black mt-16 flex cursor-pointer items-center justify-between border-t border-black p-4 ${!theyDeliver ? `border-b border-b-gray-300` : `border-b`} `}
            onClick={() => setTheyDeliver(!theyDeliver)}
          >
            <label htmlFor="acceptTerms" className="text-md mr-4 w-2/3 text-gray-900">
              Ønsker du at Fikse skal hente og levere plagget?
            </label>
            <div className="relative h-12 w-12">
              <input
                id="acceptTerms"
                type="checkbox"
                checked={!theyDeliver}
                readOnly
                className="h-full w-full cursor-pointer appearance-none rounded-full border-2 border-black"
              />
              <div
                className={`absolute inset-0 m-[1pt] flex items-center justify-center rounded-full transition-colors duration-300 ${
                  !theyDeliver ? "bg-[#8cd7ff]" : ""
                }`}
              >
                {!theyDeliver && <CheckIcon className="h-5 w-5" />}
              </div>
            </div>
          </div>
          {!theyDeliver && (
            <>
              <div>
                <label className="mt-2 block p-4 text-sm font-medium text-gray-700">
                  {"Hvor skal vi hente og returnere plagget?"}
                </label>
                <input
                  type="text"
                  value={deliveryAddress}
                  onChange={(e) => setDeliveryAddress(e.target.value)}
                  placeholder={"Addresse, postnummer"}
                  className="text-md block w-full rounded-none border-b border-t border-black bg-[#f7f6eb] p-4"
                />
              </div>

              <div className="pb-4">
                <input
                  type="text"
                  value={deliveryDepartment}
                  onChange={(e) => setDeliveryDepartment(e.target.value)}
                  placeholder={"Avdeling, f.eks.: resepsjonen på Sinsen skole"}
                  className="text-md block w-full rounded-none border-b border-black bg-[#f7f6eb] p-4"
                />
              </div>
            </>
          )}
          <div className="border-b border-black pb-4">
            <h2 className="mt-4 p-4 text-lg font-medium text-gray-900">
              {t("checkoutWorkwear.orderSummary")}
            </h2>
            {garments.map((item, index) => (
              <div key={index} className="mt-2 flex items-center justify-between p-4">
                <span>
                  {item.item} - {item.problem} {item.count ? " x " + item.count : ""}
                </span>
                <span>{item.price_nok} kr</span>
              </div>
            ))}
            <div className="mt-2 flex items-center justify-between">
              <span className="p-4">{t("checkoutWorkwear.totalInclVAT")}</span>
              <span className="p-4">{calculateTotalPrice(garments)} kr</span>
            </div>
          </div>

          <div className="mt-6 border-b border-black pb-4 pl-4">
            <p className="text-sm text-gray-500">
              {t("checkoutWorkwear.trackingNotice")}
            </p>
          </div>

          <div className="my-2 mt-6 flex justify-center pb-4">
            <FikseCatLady className="h-auto w-24" />
          </div>
          <button
            type="button"
            onClick={handlePaymentClick}
            className={`mb-12 mt-auto w-full rounded-none border-b border-t border-black py-4 pl-4 text-left text-lg ${
              isFormValid
                ? "bg-[#D3FF8C] text-black"
                : "cursor-not-allowed bg-[#e0ffd0] text-gray-500"
            }`}
            disabled={!isFormValid || uploading}
          >
            {uploading
              ? t("checkoutWorkwear.uploading")
              : t("checkoutWorkwear.verifyAndConfirm")}
          </button>
        </div>

        <div className="my-2 text-center text-xs">
          <span>fikse.co ©</span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutWorkwear;
