import { Fragment, useEffect, useState } from "react";

import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../../firebase";

// Importer Firestore-initialiseringen fra firebase.ts

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Order {
  id: string;
  contactInfo: {
    address: string;
    department: string;
    name: string;
    phone: string;
    email: string;
  };
  date: string;
  email: string;
  orderId: string;
  orderInfo: Array<{
    damageCategory: string;
    damageDescription: string;
    estimatedPrice: number;
    garmentType: string;
    imageUrl: string;
  }>;
}

export default function OrderOverview() {
  const [orders, setOrders] = useState<Order[]>([]);
  const userEmail = localStorage.getItem("uid");
  console.log(userEmail);

  useEffect(() => {
    console.log(userEmail);
    const fetchOrders = async () => {
      if (!userEmail) return;

      const q = query(
        collection(db, "municipalityOrders"),
        where("email", "==", userEmail)
      );
      const querySnapshot = await getDocs(q);
      const userOrders: Order[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Order[];
      setOrders(userOrders);
    };

    fetchOrders();
  }, []);

  const totalOrders = orders.length;
  const totalGarments = orders.reduce((sum, order) => sum + order.orderInfo.length, 0);
  const totalPrice = orders.reduce(
    (sum, order) =>
      sum + order.orderInfo.reduce((innerSum, item) => innerSum + item.estimatedPrice, 0),
    0
  );

  return (
    <div className="m-12 mx-8">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900">Mine bestillinger</h2>
        <div className="mt-4 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="rounded-lg bg-white p-4 shadow">
            <div className="text-gray-500">Antall bestillinger</div>
            <div className="text-2xl font-bold text-gray-900">{totalOrders}</div>
          </div>
          <div className="rounded-lg bg-white p-4 shadow">
            <div className="text-gray-500">Antall plagg</div>
            <div className="text-2xl font-bold text-gray-900">{totalGarments}</div>
          </div>
          <div className="rounded-lg bg-white p-4 shadow">
            <div className="text-gray-500">Total sum</div>
            <div className="text-2xl font-bold text-gray-900">{totalPrice} kr</div>
          </div>
        </div>
      </div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-4 xl:gap-x-8"
      >
        {orders.map((order) => (
          <li
            key={order.id}
            className="overflow-hidden rounded-xl border border-gray-200"
          >
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
              <img
                src={order.orderInfo[0]?.imageUrl || "https://via.placeholder.com/48"}
                alt={order.orderId}
                className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
              />
              <div className="text-sm font-medium leading-6 text-gray-900">
                {order.orderId}
              </div>
              <Menu as="div" className="relative ml-auto">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Open options</span>
                  <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? "bg-gray-50" : "",
                            "block px-3 py-1 text-sm leading-6 text-gray-900"
                          )}
                        >
                          Mer kommer
                          <span className="sr-only">, {order.orderId}</span>
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Dato</dt>
                <dd className="text-gray-700">
                  <time dateTime={new Date(order.date).toISOString()}>
                    {new Date(order.date).toLocaleDateString()}
                  </time>
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Estimert pris</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="font-medium text-gray-900">
                    {order.orderInfo.reduce((sum, item) => sum + item.estimatedPrice, 0)}{" "}
                    kr
                  </div>
                </dd>
              </div>
              {order.orderInfo.map((item, index) => (
                <div key={index} className="py-3">
                  <dt className="text-gray-500">{item.garmentType}</dt>
                  <dd className="text-gray-700">{item.damageDescription}</dd>
                </div>
              ))}
            </dl>
          </li>
        ))}
      </ul>
    </div>
  );
}
