import { useState } from "react";

import OrderModal from "../newOrder/OrderModal";

export default function Categories() {
  const [isOrderModalOpen, setOrderModalOpen] = useState(false);

  return (
    <div className="-m-4 grid grid-cols-2 p-4">
      <div className="flex flex-col items-start justify-start border border-black p-8">
        <div className="mb-4 ml-4 text-2xl">Clothes</div>
        <img
          src="https://www.fikse.co/images/illustration/category-clothes.svg"
          alt="Clothes"
          className="h-28 w-28"
        />
      </div>
      <div className="flex flex-col items-start justify-start border border-black p-8">
        <div className="mb-4 ml-4 text-2xl">Shoes</div>
        <img
          src="https://www.fikse.co/images/illustration/category-shoes.svg"
          alt="Shoes"
          className="h-28 w-28"
        />
      </div>
      <div className="flex flex-col items-start justify-start border border-black p-8">
        <div className="mb-4 ml-4 text-2xl">Accessories</div>
        <img
          src="https://www.fikse.co/images/illustration/category-accessories.svg"
          alt="Accessories"
          className="h-28 w-28"
        />
      </div>
      <div className="flex flex-col items-start justify-start border border-black p-8">
        <div className="mb-4 ml-4 text-2xl">Bunad</div>
        <img
          src="https://www.fikse.co/images/illustration/category-bunad.svg"
          alt="Bunad"
          className="h-28 w-28"
        />
      </div>
      <div
        onClick={() => setOrderModalOpen(true)}
        className="flex cursor-pointer flex-col items-start justify-start border border-black p-8 hover:bg-green-100"
      >
        <div className="mb-4 ml-4 text-2xl">Workwear</div>
        <img
          src="https://www.fikse.co/images/illustration/footer-woman-cat.svg"
          alt="Accessories"
          className="h-28 w-28"
        />
      </div>
      <div className="flex flex-col items-start justify-start border border-black p-8">
        <div className="mb-4 ml-4 text-2xl">Placeholder</div>
      </div>
      {isOrderModalOpen && (
        <OrderModal open={isOrderModalOpen} onClose={() => setOrderModalOpen(false)} />
      )}
    </div>
  );
}
