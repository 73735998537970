import { useEffect, useState } from "react";

import { getAuth } from "firebase/auth";

import { getMicrosoftUserInfo, signInWithMicrosoft, signOutUser } from "../auth";
import NavBar from "../components/NavBar";

export default function TestMedMicrosoftOslo() {
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMicrosoftUser, setIsMicrosoftUser] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setIsLoggedIn(!!user);
      if (user) {
        const provider = user.providerData.find(
          (provider) => provider.providerId === "microsoft.com"
        );
        setIsMicrosoftUser(!!provider);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGetInfo = async () => {
    const info = await getMicrosoftUserInfo();
    setUserInfo(info);
  };

  const handleSendEmail = () => {
    if (!userInfo) return;

    const mailtoLink = `mailto:simon@fikse.co?subject=${encodeURIComponent(
      "Microsoft raw data"
    )}&body=${encodeURIComponent(JSON.stringify(userInfo, null, 2))}`;

    window.location.href = mailtoLink;
  };

  const handleSwitchToMicrosoft = async () => {
    await signOutUser();
    await signInWithMicrosoft();
  };

  return (
    <>
      <NavBar />
      <div className="container mx-auto p-4">
        {!isLoggedIn && (
          <button
            onClick={signInWithMicrosoft}
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            Sign in with Microsoft
          </button>
        )}

        {isLoggedIn && !isMicrosoftUser && (
          <div>
            <p className="mb-4">You are not logged in with Microsoft.</p>
            <button
              onClick={handleSwitchToMicrosoft}
              className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              Switch to Microsoft Login
            </button>
          </div>
        )}

        {isLoggedIn && isMicrosoftUser && (
          <div className="space-y-4">
            <button
              onClick={handleGetInfo}
              className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              Get Microsoft User Info
            </button>

            {userInfo && (
              <div>
                <button
                  onClick={handleSendEmail}
                  className="mt-4 rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700"
                >
                  Send this data to Simon
                </button>
                <div className="mt-4 rounded border bg-gray-50 p-4">
                  <pre className="whitespace-pre-wrap">
                    {JSON.stringify(userInfo, null, 2)}
                  </pre>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
