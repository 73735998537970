import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SignIcon } from "../../images/icons-fikse/sign.svg";

const ConfirmationView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the full order ID from the URL
  const fullOrderId = window.location.pathname.split("/").pop();

  // Extract the first 5 letters from the order ID
  const referenceCode = fullOrderId ? fullOrderId.slice(0, 5) : "";

  const handleGoForward = () => {
    // Remove #newOrder from the URL
    window.history.replaceState(null, "", " ");

    // Reload the page to remove the #newOrder and show the order details
    window.location.reload();
  };

  return (
    <div className="flex h-screen flex-col justify-between">
      <div className="p-6">
        <div className="flex items-start justify-between">
          <h1 className="text-2xl font-medium">{t("confirmationView.delivery")}</h1>
        </div>
        <div className="mt-12">
          <div className="mb-4 flex items-center">
            <span className="text-lg">{t("confirmationView.labelOrder")}</span>
          </div>

          <div className="flex items-center">
            <SignIcon className="h-12 w-12" />
            <div className="ml-4 text-4xl font-bold">{referenceCode}</div>
          </div>
          <p className="mt-4 text-gray-700">{t("confirmationView.gatherItems")}</p>
          <p className="mt-4 text-gray-700">{t("confirmationView.sameDayPickup")}</p>
        </div>
      </div>
      <button
        onClick={handleGoForward}
        /* className="mb-12 flex w-full justify-between border-b border-t border-black bg-lime-200 p-4 text-lg"*/
        className="fixed bottom-0 left-0 flex w-full items-center justify-between border-b border-t border-black bg-fikseGreen p-4 px-8 py-4 text-xl"
      >
        <span>{t("confirmationView.proceed")}</span>
        <span>&gt;</span>
      </button>
    </div>
  );
};

export default ConfirmationView;
