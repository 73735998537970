import { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';

interface AddServiceCategoryModalWithCodeProps {
  onClose: () => void;
  onSave: (newServiceCategory: any) => void;
}

export const AddServiceCategoryModalWithCode = ({
  onClose,
  onSave,
}: AddServiceCategoryModalWithCodeProps) => {
  const [categoryName, setCategoryName] = useState('');

  const services = [
    {
      id: "5154121",
      item: "Gardiner",
      problem: "Forkorte",
      work: "N/A",
      description: "Forkorte opptil 150 cm",
      fix_type: "Liten fiks",
      price_nok: 299,
      placeholder: "Hvor mange cm skal forkortes?"
    },
    {
      id: "5154221",
      item: "Gardiner",
      problem: "Forkorte",
      work: "N/A",
      description: "Forkorte opptil 150 cm med 2 lag (inkludert innerfôr)",
      fix_type: "Stor fiks",
      price_nok: 499,
      placeholder: "Hvor mange cm skal forkortes?"
    },
    {
      id: "5154122",
      item: "Gardiner",
      problem: "Forkorte",
      work: "N/A",
      description: "Forkorte opptil 300 cm",
      fix_type: "Liten fiks",
      price_nok: 599,
      placeholder: "Hvor mange cm skal forkortes?"
    },
    {
      id: "5154222",
      item: "Gardiner",
      problem: "Forkorte",
      work: "N/A",
      description: "Forkorte opptil 300 cm med 2 lag (inkludert innerfôr)",
      fix_type: "Stor fiks",
      price_nok: 999,
      placeholder: "Hvor mange cm skal forkortes?"
    },
    {
      id: "5151111",
      item: "Gardiner",
      problem: "Hull/Søm",
      work: "N/A",
      description: "Egnet for ett hull eller liten søm",
      fix_type: "Liten fiks",
      price_nok: 199,
      placeholder: "Beskriv plasseringen av hullet, og eventuelle andre preferanser"
    },
    {
      id: "5151211",
      item: "Gardiner",
      problem: "Hull/Søm",
      work: "N/A",
      description: "Egnet for flere hull eller større skader",
      fix_type: "Stor fiks",
      price_nok: 399,
      placeholder: "Beskriv plasseringen av hullet, og eventuelle andre preferanser"
    },
    {
      id: "51517111",
      item: "Gardiner",
      problem: "Rynkebånd",
      work: "N/A",
      description: "Sy gardin med rynkebånd opptil 150 cm bredde inkl. bånd",
      fix_type: "Liten fiks",
      price_nok: 599,
      placeholder: "Spesifiser bredden på rynkebåndet i beskrivelsen: 30mm, 65mm, eller 75mm"
    },
    {
      id: "51517211",
      item: "Gardiner",
      problem: "Rynkebånd",
      work: "N/A",
      description: "Sy gardin med rynkebånd opptil 300 cm bredde inkl. bånd",
      fix_type: "Stor fiks",
      price_nok: 999,
      placeholder: "Spesifiser bredden på rynkebåndet i beskrivelsen: 30mm, 65mm, eller 75mm"
    },
    {
      id: "5161111",
      item: "Kosebamse",
      problem: "Søm",
      work: "N/A",
      description: "Liten søm åpning",
      fix_type: "Liten fiks",
      price_nok: 99,
      placeholder: "Beskriv plasseringen av problemet, og eventuelle andre preferanser"
    },
    {
      id: "5161211",
      item: "Kosebamse",
      problem: "Søm",
      work: "N/A",
      description: "Større søm åpning",
      fix_type: "Stor fiks",
      price_nok: 299,
      placeholder: "Beskriv plasseringen av problemet, og eventuelle andre preferanser"
    },
    {
      id: "5161311",
      item: "Kosebamse",
      problem: "Hull",
      work: "N/A",
      description: "Ett lite hull",
      fix_type: "Liten fiks",
      price_nok: 199,
      placeholder: "Beskriv plasseringen av problemet, og eventuelle andre preferanser"
    },
    {
      id: "5161411",
      item: "Kosebamse",
      problem: "Hull",
      work: "N/A",
      description: "Flere hull eller stort hull",
      fix_type: "Stor fiks",
      price_nok: 399,
      placeholder: "Beskriv plasseringen av problemet, og eventuelle andre preferanser"
    },
    {
      id: "5171111",
      item: "Håndkle",
      problem: "Rens",
      work: "N/A",
      description: "Rens av håndkle",
      fix_type: "Standard",
      price_nok: 18,
      placeholder: "Beskriv plasseringen av problemet, og eventuelle andre preferanser"
    }];

  const handleSave = async () => {
    if (!categoryName || services.length === 0) return;

    // Legg til nytt dokument i Firestore-samlingen
    const newCategory = {
      name: categoryName,
      categories: services,
      createdAt: new Date(),
    };

    try {
      const docRef = await addDoc(collection(db, 'serviceCategories'), newCategory);
      onSave({ ...newCategory, id: docRef.id });
    } catch (e) {
      console.error('Error adding document: ', e);
    }

    onClose();
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white p-6 rounded shadow-lg max-w-md w-full">
          <h2 className="text-xl font-semibold mb-4">Add Service Category</h2>
          <input
            type="text"
            placeholder="Category Name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            className="border p-2 w-full mb-4"
          />
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white py-2 px-4 rounded mr-2"
            disabled={!categoryName || services.length === 0}
          >
            Save
          </button>
          <button onClick={onClose} className="bg-gray-300 text-black py-2 px-4 rounded">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

