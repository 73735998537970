export default function Tracking() {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
              Sporing av klær under reparasjon
            </h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">
                I denne løsningen kan du følge plaggene dine fra de sendes inn til
                reparasjon og til de returneres ferdig fikset hos deg.
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <a
                  href="#"
                  className="rounded-md bg-[#FF8300] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Kommer snart
                </a>
                <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                  Kontakt dev@fikse.co <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <img
              src="https://www.fikse.co/images/cover-photo.png"
              alt=""
              className="aspect-[6/5] w-full max-w-lg rounded-2xl object-cover lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2"
            />
          </div>
          <div className="mt-10">
            <h2 className="text-xl font-semibold text-gray-900">
              Slik fungerer sporingen:
            </h2>
            <ul className="mt-4 list-decimal pl-5">
              <li className="mt-2 flex">
                <div className="my-8 mr-8">
                  <strong>1. Legg inn din bestilling</strong>
                  <br />
                  Velg kategori og type fiks. Husk å legge til beskrivelse, for eksempel
                  farge på glidelås.
                  <br />
                </div>
                <img
                  className="h-auto w-20"
                  src="https://www.fikse.co/images/illustration/category-accessories.svg"
                  alt="Kategori tilbehør"
                />
              </li>
              <li className="mt-2 flex">
                <img
                  className="h-auto w-20"
                  src="https://www.fikse.co/images/illustration/footer-woman-cat.svg"
                  alt="Levering og henting"
                />
                <div className="my-8 ml-8">
                  <strong>2. Velg hvordan du ønsker å levere og hente</strong>
                  <br />
                  Om du ønsker hjelp eller råd må du levere selv, ellers kan du sende med
                  bud eller levere til en av våre drop off stasjoner.
                  <br />
                </div>
              </li>
              <li className="mt-2 flex">
                <div className="my-8 mr-8">
                  <strong>3. Betal og få beskjed når det er klart</strong>
                  <br />
                  Bestillingen din blir reparert av en av våre dyktige reparatører og er
                  ferdig fikset innen 10 dager.
                  <br />
                </div>
                <img
                  className="h-auto w-20"
                  src="https://www.fikse.co/images/illustration/category-bunad.svg"
                  alt="Ferdig reparasjon"
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}
