// useAuth.ts
import { useEffect, useState } from "react";

import { User, getAuth } from "firebase/auth";

const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });

    return () => unsubscribe();
  }, [auth]);

  return user;
};

export default useAuth;
