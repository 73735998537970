import { Fragment, useEffect, useState } from "react";

import { collection, getDocs, query } from "firebase/firestore";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

import { db } from "../../firebase";

// Import Firestore initialization from firebase.ts

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface MunicipalityOrder {
  id: string;
  status: string;
  contactInfo: {
    address: string;
    department: string;
    name: string;
    phone: string;
    email: string;
  };
  date: string;
  email: string;
  customerRef: string;
  orderInfo: Array<{
    fixId: string;
    damageCategory: string;
    damageDescription: string;
    estimatedPrice: number;
    garmentType: string;
    imageUrl: string;
  }>;
}

interface DirectOrder {
  id: string;
  companyCode: string;
  officeCode: string;
  contactInformation: {
    city: string;
    email: string;
    floor: string;
    name: string;
    phoneNumber: string;
    postCode: string;
    street: string;
    streetNumber: string;
    time: string;
    value: string;
  };
  dateOrdered: string;
  deliveryFrom: string;
  deliveryPrice: number;
  deliveryTo: string;
  dropOffLocation: string;
  dropOffOrder: boolean;
  express: boolean;
  expressPrice: number;
  order: Array<{
    category: string;
    description: string;
    eligibleForExpress: boolean;
    id: string;
    price: number;
    product: string;
    paid: boolean;
    pickUpLocation: string;
    pickUpOrder: boolean;
    receiveOrder: boolean;
    sendOrder: boolean;
    vippsToken: string;
  }>;
  paid: boolean;
  pickUpOrder: boolean;
  price: number;
  receiveOrder: boolean;
  sendOrder: boolean;
  pickUpLocation: string;
}

export default function OrderOverview() {
  const [municipalityOrders, setMunicipalityOrders] = useState<MunicipalityOrder[]>([]);
  const [directOrders, setDirectOrders] = useState<DirectOrder[]>([]);
  const [showDetails, setShowDetails] = useState<{ [key: string]: boolean }>({});
  const [source, setSource] = useState<"osloKommune" | "fikseCo">("osloKommune");
  const [orders, setOrders] = useState<any[]>([]);
  const [allOrders, setAllOrders] = useState<any[]>([]);
  const [uniqueCompanyCodes, setUniqueCompanyCodes] = useState<string[]>([]);
  const [selectedCompanyCode, setSelectedCompanyCode] = useState<string>("");
  const [selectedRepairType, setSelectedRepairType] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedDelivery, setSelectedDelivery] = useState<string>("");
  const [selectedExpressStatus, setSelectedExpressStatus] = useState<string>("");
  const [selectedPickUpLocation, setSelectedPickUpLocation] = useState<string>("");
  const [selectedDropOffLocation, setSelectedDropOffLocation] = useState<string>("");
  const [uniquePickUpLocations, setUniquePickUpLocations] = useState<string[]>([]);
  const [uniqueDropOffLocations, setUniqueDropOffLocations] = useState<string[]>([]);
  const [uniqueRepairTypes, setUniqueRepairTypes] = useState<string[]>([]);
  const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
  const [uniqueDeliveries, setUniqueDeliveries] = useState<string[]>([]);
  const [filterByCompanyCode, setFilterByCompanyCode] = useState<"yes" | "no" | "">("");
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [metrics, setMetrics] = useState<{
    totalNumberOfOrders: number;
    averagePrice: string;
    totalRevenue: string;
  }>({ totalNumberOfOrders: 0, averagePrice: "0.00", totalRevenue: "0.00" });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const yearRange = Array.from({ length: 2 }, (_, i) => currentYear - i);

  const userEmail = localStorage.getItem("uid");

  useEffect(() => {
    const fetchMunicipalityOrders = async () => {
      if (!userEmail) return;

      const q = query(collection(db, "municipalityOrders"));
      const querySnapshot = await getDocs(q);
      const orders: MunicipalityOrder[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as MunicipalityOrder[];

      // Sort orders by date in descending order
      orders.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

      setMunicipalityOrders(orders);
      setAllOrders(orders);
    };

    const fetchDirectOrders = async () => {
      if (!userEmail) return;

      const q = query(collection(db, "orders"));
      const querySnapshot = await getDocs(q);
      const orders: DirectOrder[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as DirectOrder[];

      // Sort orders by date in descending order
      orders.sort(
        (a, b) => new Date(b.dateOrdered).getTime() - new Date(a.dateOrdered).getTime()
      );

      setDirectOrders(orders);
      setAllOrders(orders);
    };

    if (source === "osloKommune") {
      fetchMunicipalityOrders();
    } else {
      fetchDirectOrders();
    }
  }, [userEmail, source]);

  useEffect(() => {
    const uniqueCodes = Array.from(
      new Set(allOrders.map((order) => order.companyCode).filter((code) => code))
    ).sort();
    const repairTypes = Array.from(
      new Set(
        allOrders
          .flatMap((order) => order.order?.map((item: any) => item.product))
          .filter((type) => type)
      )
    );
    const categories = Array.from(
      new Set(
        allOrders
          .flatMap((order) => order.order?.map((item: any) => item.category))
          .filter((category) => category)
      )
    );
    const deliveries = Array.from(
      new Set(
        allOrders
          .map((order) => {
            if (order.dropOffOrder) return "DropOff";
            if (order.pickUpOrder) return "PickUp";
            if (order.sendOrder) return "Send";
            return "Unknown";
          })
          .filter((delivery) => delivery !== "Unknown")
      )
    );

    const pickUpLocations = Array.from(
      new Set(
        allOrders.map((order) => order.pickUpLocation).filter((location) => location)
      )
    );
    const dropOffLocations = Array.from(
      new Set(
        allOrders.map((order) => order.dropOffLocation).filter((location) => location)
      )
    );

    setUniqueCompanyCodes(uniqueCodes);
    setUniqueRepairTypes(repairTypes);
    setUniqueCategories(categories);
    setUniqueDeliveries(deliveries);
    setUniquePickUpLocations(pickUpLocations);
    setUniqueDropOffLocations(dropOffLocations);
  }, [allOrders]);

  useEffect(() => {
    const filteredOrders = allOrders
      .filter(
        (order) => !selectedCompanyCode || order.companyCode === selectedCompanyCode
      )
      .filter(
        (order) =>
          !selectedRepairType ||
          order.order?.some((item: any) => item.product === selectedRepairType)
      )
      .filter(
        (order) =>
          !selectedCategory ||
          order.order?.some((item: any) => item.category === selectedCategory)
      )
      .filter((order) => {
        if (!selectedDelivery) return true;
        if (selectedDelivery === "DropOff") return order.dropOffOrder;
        if (selectedDelivery === "PickUp") return order.pickUpOrder;
        if (selectedDelivery === "Send") return order.sendOrder;
        return false;
      })
      .filter((order) => {
        if (filterByCompanyCode === "yes") return order.companyCode;
        if (filterByCompanyCode === "no") return !order.companyCode;
        return true;
      })
      .filter((order) => {
        if (!selectedMonth && !selectedYear) return true;

        const [year, month] =
          (order.dateOrdered || order.date)?.split("-").map(Number) || [];
        const isMonthMatch = selectedMonth ? month === parseInt(selectedMonth) + 1 : true;
        const isYearMatch = selectedYear ? year === parseInt(selectedYear) : true;

        return isMonthMatch && isYearMatch;
      })
      .filter((order) => {
        if (selectedExpressStatus === "") return true;
        if (selectedExpressStatus === "yes") return order.express;
        if (selectedExpressStatus === "no") return !order.express;
        return true;
      })
      .filter(
        (order) =>
          !selectedPickUpLocation || order.pickUpLocation === selectedPickUpLocation
      )
      .filter((order) => {
        return (
          !selectedDropOffLocation || order.dropOffLocation === selectedDropOffLocation
        );
      })
      .sort((a, b) => {
        const dateA = new Date(a.dateOrdered || a.date).getTime();
        const dateB = new Date(b.dateOrdered || a.date).getTime();
        return dateB - dateA;
      });

    setOrders(filteredOrders);
  }, [
    selectedCompanyCode,
    selectedRepairType,
    selectedCategory,
    selectedDelivery,
    allOrders,
    filterByCompanyCode,
    selectedMonth,
    selectedYear,
    selectedExpressStatus,
    selectedPickUpLocation,
    selectedDropOffLocation,
  ]);

  useEffect(() => {
    const calculateKeyMetrics = (orders: any[]) => {
      const filteredOrders = orders.filter((order) => {
        const price = Number(
          order.price ||
            order.orderInfo?.reduce(
              (sum: number, item: any) => sum + item.estimatedPrice,
              0
            ) ||
            0
        );
        return price >= 10 && price <= 10000;
      });

      const totalNumberOfOrders = filteredOrders.length;
      const totalRevenue = filteredOrders.reduce(
        (sum, order) =>
          sum +
          Number(
            order.price ||
              order.orderInfo?.reduce(
                (sum: number, item: any) => sum + item.estimatedPrice,
                0
              ) ||
              0
          ),
        0
      );
      const averagePrice =
        totalNumberOfOrders > 0 ? totalRevenue / totalNumberOfOrders : 0;

      return {
        totalNumberOfOrders,
        averagePrice: averagePrice.toFixed(0),
        totalRevenue: totalRevenue.toFixed(0),
      };
    };

    const calculatedMetrics = calculateKeyMetrics(orders);
    setMetrics({
      totalNumberOfOrders: calculatedMetrics.totalNumberOfOrders,
      averagePrice: calculatedMetrics.averagePrice,
      totalRevenue: calculatedMetrics.totalRevenue,
    });
  }, [orders]);

  const toggleDetails = (orderId: string) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [orderId]: !prevDetails[orderId],
    }));
  };

  const confirmOrder = (orderId: string) => {
    if (source === "osloKommune") {
      setMunicipalityOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, status: "confirmed" } : order
        )
      );
    } else {
      setDirectOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId
            ? {
                ...order,
                order: order.order.map((item) => ({
                  ...item,
                  receiveOrder: true,
                })),
              }
            : order
        )
      );
    }
  };

  const handleSourceChange = (newSource: "osloKommune" | "fikseCo") => {
    setSource(newSource);
  };

  const formatDateToDayMonthYear = (isoDate: string): string => {
    const [year, month, day] = isoDate.split("-").map(Number);
    return `${day.toString().padStart(2, "0")}.${month.toString().padStart(2, "0")}.${year}`;
  };

  const generateHeaders = (order: any) => {
    return Object.keys(order).map((key) => key.replace(/([A-Z])/g, " $1").toUpperCase());
  };

  const exportToExcel = () => {
    const summary = [
      { label: "Number of Orders", value: metrics.totalNumberOfOrders },
      { label: "Average Total Price", value: `${metrics.averagePrice} kr` },
      { label: "Total Revenue", value: `${metrics.totalRevenue} kr` },
    ];

    const summarySheet = XLSX.utils.json_to_sheet(
      summary.map((item) => ({ Description: item.label, Value: item.value }))
    );
    const ordersSheet = XLSX.utils.json_to_sheet(orders);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, summarySheet, "Summary");
    XLSX.utils.book_append_sheet(workbook, ordersSheet, "Orders");

    const timestamp = new Date().toISOString().replace(/[-:T.]/g, "");
    XLSX.writeFile(workbook, `Orders_${timestamp}.xlsx`);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const headers = generateHeaders(orders[0]);

    // Add summary
    doc.text("Orders Report", 20, 10);
    doc.text(`Number of Orders: ${metrics.totalNumberOfOrders}`, 20, 20);
    doc.text(`Average Total Price: ${metrics.averagePrice} kr`, 20, 30);
    doc.text(`Total Revenue: ${metrics.totalRevenue} kr`, 20, 40);

    doc.autoTable({
      head: [headers],
      body: orders.map((order) => [
        order.id,
        formatDateToDayMonthYear(order.dateOrdered || order.date),
        `${order.price || order.orderInfo?.reduce((sum: number, item: any) => sum + item.estimatedPrice, 0)} kr`,
        order.customerRef || "",
        order.contactInfo?.department || "",
        order.contactInformation?.name || order.contactInfo?.name,
        order.contactInformation?.email || order.contactInfo?.email,
        order.contactInformation?.phoneNumber || order.contactInfo?.phone,
        `${order.contactInformation?.street || order.contactInfo?.address} ${order.contactInformation?.streetNumber || ""}`,
        `${order.contactInformation?.postCode || order.contactInfo?.address} ${order.contactInformation?.city || ""}`,
      ]),
      startY: 50,
    });

    const timestamp = new Date().toISOString().replace(/[-:T.]/g, "");
    doc.save(`Orders_${timestamp}.pdf`);
  };

  const renderOrders = (): JSX.Element => {
    const headers = orders.length > 0 ? generateHeaders(orders[0]) : [];

    return (
      <>
        <thead>
          <tr>
            {headers.map((header) => (
              <th
                key={header}
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {orders.map((order) => (
            <Fragment key={order.id}>
              <tr>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  {order.id.substring(0, 6)}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  <time
                    dateTime={new Date(order.dateOrdered || order.date).toISOString()}
                  >
                    {formatDateToDayMonthYear(order.dateOrdered || order.date)}
                  </time>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  {order.price ||
                    order.orderInfo?.reduce(
                      (sum: number, item: any) => sum + item.estimatedPrice,
                      0
                    )}{" "}
                  kr
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  {order.customerRef || ""}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  {order.contactInfo?.department || ""}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  {order.contactInformation?.name || order.contactInfo?.name}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  {order.contactInformation?.email || order.contactInfo?.email}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  {order.contactInformation?.phoneNumber || order.contactInfo?.phone}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  {order.contactInformation?.street || order.contactInfo?.address}{" "}
                  {order.contactInformation?.streetNumber || ""}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  {order.contactInformation?.postCode || order.contactInfo?.address}{" "}
                  {order.contactInformation?.city || ""}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                  <button
                    className="text-blue-600 hover:text-blue-900"
                    onClick={() => toggleDetails(order.id)}
                  >
                    {showDetails[order.id] ? "Hide" : "Show"}
                  </button>
                  <button
                    className="ml-4 text-green-600 hover:text-green-900"
                    onClick={() => confirmOrder(order.id)}
                  >
                    {order.order?.some((item: any) => item.receiveOrder)
                      ? "Done"
                      : "Confirm"}
                  </button>
                </td>
              </tr>
              {showDetails[order.id] && (
                <tr>
                  <td colSpan={headers.length} className="px-6 py-4">
                    <div className="rounded-lg bg-gray-50 p-4">
                      {order.order?.map((item: any, index: number) => (
                        <div key={index} className="mb-4">
                          <div className="text-gray-700">
                            {index + 1}: {item.product}
                          </div>
                          <div className="text-gray-500">{item.description}</div>
                          <div className="text-gray-500">Category: {item.category}</div>
                          <div className="text-gray-500">Price: {item.price} kr</div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </>
    );
  };

  return (
    <div className="mx-8 my-8">
      <header className="mb-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">All Orders</h1>
        <p className="text-lg text-gray-600">Here is an overview of current repairs.</p>
        <div className="mt-4 flex justify-between space-x-4">
          <div>
            <button
              className={classNames(
                source === "osloKommune"
                  ? "border border-black bg-[#8CD7FF]"
                  : "border border-black bg-gray-200 text-gray-700",
                "px-4 py-2"
              )}
              onClick={() => handleSourceChange("osloKommune")}
            >
              Oslo Municipality
            </button>
            <button
              className={classNames(
                source === "fikseCo"
                  ? "border border-black bg-[#8CD7FF]"
                  : "border border-black bg-gray-200 text-gray-700",
                "px-4 py-2"
              )}
              onClick={() => handleSourceChange("fikseCo")}
            >
              Fikse.co
            </button>
          </div>
          <div>
            <button
              className="border border-black bg-[#C1E1FF] px-4 py-2"
              onClick={exportToExcel}
            >
              Export to Excel
            </button>
            <button
              className="border border-black bg-[#FFABAB] px-4 py-2"
              onClick={exportToPDF}
            >
              Export to PDF
            </button>
          </div>
        </div>
      </header>
      <div className="mb-8 flex justify-between space-x-4">
        <div className="flex-1 border border-black bg-[#8CD7FF] p-4 text-blue-700">
          <span className="block text-sm text-black">Antall bestillinger:</span>
          <span className="block text-xl font-semibold text-black">
            {metrics.totalNumberOfOrders}
          </span>
        </div>
        <div className="flex-1 border border-black bg-[#D4AF37] p-4 text-green-700">
          <span className="block text-sm text-black">Gjennomsnittlig totalpris:</span>
          <span className="block text-xl font-semibold text-black">
            {metrics.averagePrice} kr
          </span>
        </div>
        <div className="flex-1 border border-black bg-[#32CD32] p-4 text-yellow-700">
          <span className="block text-sm text-black">Sum omsetning:</span>
          <span className="block text-xl font-semibold text-black">
            {metrics.totalRevenue} kr
          </span>
        </div>
      </div>
      <div className="mb-8 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <div>
          <label
            htmlFor="companyCodePresenceFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Firmakunde?{" "}
          </label>
          <select
            id="companyCodePresenceFilter"
            value={filterByCompanyCode}
            onChange={(e) => setFilterByCompanyCode(e.target.value as "yes" | "no" | "")}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            <option value="yes">Ja</option>
            <option value="no">Nei</option>
          </select>
        </div>
        <div>
          <label
            htmlFor="companyCodeFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Type firmakode:{" "}
          </label>
          <select
            id="companyCodeFilter"
            value={selectedCompanyCode}
            onChange={(e) => setSelectedCompanyCode(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            {uniqueCompanyCodes.map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="repairTypeFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Reparasjonstype:{" "}
          </label>
          <select
            id="repairTypeFilter"
            value={selectedRepairType}
            onChange={(e) => setSelectedRepairType(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            {uniqueRepairTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="categoryFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Kategori:{" "}
          </label>
          <select
            id="categoryFilter"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            {uniqueCategories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="deliveryFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Levering:{" "}
          </label>
          <select
            id="deliveryFilter"
            value={selectedDelivery}
            onChange={(e) => setSelectedDelivery(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            {uniqueDeliveries.map((delivery) => (
              <option key={delivery} value={delivery}>
                {delivery}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="expressFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Ekspress:{" "}
          </label>
          <select
            id="expressFilter"
            value={selectedExpressStatus}
            onChange={(e) => setSelectedExpressStatus(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            <option value="yes">Ja</option>
            <option value="no">Nei</option>
          </select>
        </div>
        <div>
          <label
            htmlFor="monthFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Måned:{" "}
          </label>
          <select
            id="monthFilter"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            {monthNames.map((name, index) => (
              <option key={index} value={index.toString()}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="yearFilter" className="block text-sm font-medium text-gray-700">
            År:{" "}
          </label>
          <select
            id="yearFilter"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            {yearRange.map((year) => (
              <option key={year} value={year.toString()}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="dropOffLocationFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Avleveringssted:{" "}
          </label>
          <select
            id="dropOffLocationFilter"
            value={selectedDropOffLocation}
            onChange={(e) => setSelectedDropOffLocation(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            {uniqueDropOffLocations.map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="pickUpLocationFilter"
            className="block text-sm font-medium text-gray-700"
          >
            Hentested:{" "}
          </label>
          <select
            id="pickUpLocationFilter"
            value={selectedPickUpLocation}
            onChange={(e) => setSelectedPickUpLocation(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Alle</option>
            {uniquePickUpLocations.map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 border border-black bg-white">
          {renderOrders()}
        </table>
      </div>
    </div>
  );
}
