import React, { useEffect, useState } from "react";

import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../../firebase";

interface OrderStats {
  averagePrice: number;
  averageDeliveryTime: number;
  totalOrders: number;
  workTypeStats: { [key: string]: number };
  problemStats: { [key: string]: number };
  descriptionStats: { [key: string]: number };
  domain: string;
}

interface Order {
  email: string;
  date: string;
  totalPrice: string;
  orderInfo: Array<{
    work: string;
    problem: string;
    description: string;
  }>;
  activity: Array<{
    timestamp: string;
    content: string;
  }>;
  id: string;
}

const OrderOverview2: React.FC = () => {
  const domains = ["oslo.kommune.no", "osloskolen.no", "fikse.co", "ntnu.no"];

  const [selectedDomains, setSelectedDomains] = useState<Set<string>>(new Set(domains));
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [stats, setStats] = useState<OrderStats>({
    averagePrice: 0,
    averageDeliveryTime: 0,
    totalOrders: 0,
    workTypeStats: {},
    problemStats: {},
    descriptionStats: {},
    domain: "",
  });
  const [selectedWorkType, setSelectedWorkType] = useState<string | null>(null);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [selectedProblem, setSelectedProblem] = useState<string | null>(null);

  const toggleDomain = (domain: string) => {
    const newSelectedDomains = new Set(selectedDomains);
    if (newSelectedDomains.has(domain)) {
      newSelectedDomains.delete(domain);
    } else {
      newSelectedDomains.add(domain);
    }
    setSelectedDomains(newSelectedDomains);
  };

  const handleWorkTypeClick = (workType: string) => {
    if (workType === selectedWorkType) {
      resetWorkType();
    } else {
      setSelectedWorkType(workType);
      setSelectedProblem(null); // Reset problem when changing work type
    }
  };

  const handleProblemClick = (problem: string) => {
    setSelectedProblem(problem === selectedProblem ? null : problem);
  };

  const resetWorkType = () => {
    setSelectedWorkType(null);
    setSelectedProblem(null);
  };

  const resetProblem = () => {
    setSelectedProblem(null);
  };

  const fetchOrderStats = async () => {
    try {
      const ordersRef = collection(db, "workwearOrders");
      const snapshot = await getDocs(ordersRef);
      const allOrders = snapshot.docs.map((doc) => ({
        ...(doc.data() as Order),
        id: doc.id,
      }));

      // Filter orders by selected domains and date range
      const filtered = allOrders.filter((order) => {
        const email = order.email as string;
        const orderDate = new Date(order.date);
        const isValidDomain = Array.from(selectedDomains).some((domain) =>
          email?.endsWith(domain)
        );

        const isAfterStart = !startDate || orderDate >= new Date(startDate);
        const isBeforeEnd = !endDate || orderDate <= new Date(endDate);

        return isValidDomain && isAfterStart && isBeforeEnd;
      });

      setFilteredOrders(filtered);
      const stats = calculateStats(filtered);
      setStats(stats);
    } catch (error) {
      console.error("Error fetching order stats:", error);
    }
  };

  useEffect(() => {
    fetchOrderStats();
  }, [startDate, endDate, selectedDomains]); // Add selectedDomains dependency

  const calculateStats = (orders: Order[]): OrderStats => {
    const totalOrders = orders.length;
    let totalPrice = 0;
    let validPriceCount = 0;
    let totalDeliveryTime = 0;
    const workTypes: { [key: string]: number } = {};
    const problems: { [key: string]: number } = {};
    const descriptions: { [key: string]: number } = {};

    orders.forEach((order) => {
      // Calculate price (ignoring prices less than 14)
      const price = parseInt(order.totalPrice.replace(/[^0-9]/g, ""));
      if (price >= 14) {
        totalPrice += price;
        validPriceCount++;
      }

      // Calculate delivery time
      const orderDate = new Date(order.date);
      const completionActivity = order.activity.find(
        (a) => a.content === "orderStateDelivered"
      );
      if (completionActivity) {
        const completionDate = new Date(completionActivity.timestamp);
        totalDeliveryTime +=
          (completionDate.getTime() - orderDate.getTime()) / (1000 * 60 * 60 * 24);
      }

      // Calculate work type, problem, and description frequencies
      order.orderInfo.forEach((item) => {
        const workType = item.work === "N/A" ? "Annet" : item.work;
        workTypes[workType] = (workTypes[workType] || 0) + 1;
        problems[item.problem] = (problems[item.problem] || 0) + 1;
        descriptions[item.description] = (descriptions[item.description] || 0) + 1;
      });
    });

    return {
      averagePrice: validPriceCount ? totalPrice / validPriceCount : 0,
      averageDeliveryTime: totalOrders ? totalDeliveryTime / totalOrders : 0,
      totalOrders,
      workTypeStats: workTypes,
      problemStats: problems,
      descriptionStats: descriptions,
      domain: "",
    };
  };

  const renderStats = (data: { [key: string]: number }) => {
    return Object.entries(data)
      .sort(([, a], [, b]) => b - a)
      .map(([key, value]) => (
        <div key={key} className="flex justify-between py-1">
          <span className="font-medium">{key}</span>
          <span>{value}</span>
        </div>
      ));
  };

  const renderWorkTypeStats = (data: { [key: string]: number }) => {
    return Object.entries(data)
      .sort(([, a], [, b]) => b - a)
      .map(([key, value]) => (
        <div
          key={key}
          onClick={() => handleWorkTypeClick(key)}
          className={`flex cursor-pointer justify-between px-2 py-1 ${
            selectedWorkType === key ? "rounded bg-green-100" : ""
          }`}
        >
          <span className="font-medium">{key}</span>
          <span>{value}</span>
        </div>
      ));
  };

  const renderProblemStats = (data: { [key: string]: number }) => {
    return Object.entries(data)
      .sort(([, a], [, b]) => b - a)
      .map(([key, value]) => (
        <div
          key={key}
          onClick={() => handleProblemClick(key)}
          className={`flex cursor-pointer justify-between px-2 py-1 ${
            selectedProblem === key ? "rounded bg-green-100" : ""
          }`}
        >
          <span className="font-medium">{key}</span>
          <span>{value}</span>
        </div>
      ));
  };

  const getRelevantOrders = () => {
    if (!selectedWorkType && !selectedProblem) return [];
    return filteredOrders.filter((order) =>
      order.orderInfo.some((info) => {
        const workMatch =
          !selectedWorkType ||
          info.work === (selectedWorkType === "Annet" ? "N/A" : selectedWorkType);
        const problemMatch = !selectedProblem || info.problem === selectedProblem;
        return workMatch && problemMatch;
      })
    );
  };

  return (
    <div className="p-6">
      <div className="mb-6 space-y-4">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold">
            Order Statistics for Institutional Orders
          </h2>
          <div className="flex space-x-4">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="rounded-lg border p-2"
            />
            <span className="self-center">to</span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="rounded-lg border p-2"
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-2">
          {domains.map((domain) => (
            <button
              key={domain}
              onClick={() => toggleDomain(domain)}
              className={`rounded-full px-4 py-2 ${
                selectedDomains.has(domain)
                  ? "bg-green-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {domain}
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {/* General Stats */}
        <div className="rounded-lg bg-white p-4 shadow">
          <h3 className="mb-4 text-xl font-semibold">General Statistics</h3>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>Total Orders:</span>
              <span>{stats.totalOrders}</span>
            </div>
            <div className="flex justify-between">
              <span>Average Price:</span>
              <span>{stats.averagePrice.toFixed(2)} kr</span>
            </div>
            <div className="flex justify-between">
              <span>Average Delivery Time:</span>
              <span>{stats.averageDeliveryTime.toFixed(1)} days</span>
            </div>
          </div>
        </div>

        {/* Work Types */}
        <div className="rounded-lg bg-white p-4 shadow">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-xl font-semibold">
              Work Types {selectedWorkType && `- ${selectedWorkType}`}
            </h3>
            {selectedWorkType && (
              <button
                onClick={resetWorkType}
                className="rounded bg-gray-100 px-2 py-1 text-sm hover:bg-gray-200"
              >
                Reset
              </button>
            )}
          </div>
          <div className="max-h-60 overflow-y-auto">
            {renderWorkTypeStats(stats.workTypeStats)}
          </div>
        </div>

        {/* Problems */}
        <div className="rounded-lg bg-white p-4 shadow">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-xl font-semibold">
              {selectedWorkType ? `Problems for ${selectedWorkType}` : "All Problems"}
            </h3>
            {selectedProblem && (
              <button
                onClick={resetProblem}
                className="rounded bg-gray-100 px-2 py-1 text-sm hover:bg-gray-200"
              >
                Reset
              </button>
            )}
          </div>
          <div className="max-h-60 overflow-y-auto">
            {renderProblemStats(
              selectedWorkType
                ? filteredOrders
                    .flatMap((order) => order.orderInfo)
                    .filter(
                      (info) =>
                        info.work ===
                        (selectedWorkType === "Annet" ? "N/A" : selectedWorkType)
                    )
                    .reduce(
                      (acc, info) => {
                        acc[info.problem] = (acc[info.problem] || 0) + 1;
                        return acc;
                      },
                      {} as { [key: string]: number }
                    )
                : stats.problemStats
            )}
          </div>
        </div>
      </div>

      {/* Orders List */}
      {(selectedWorkType || selectedProblem) && (
        <div className="mt-6">
          <h3 className="mb-4 text-xl font-semibold">Related Orders</h3>
          <div className="rounded-lg bg-white p-4 shadow">
            <div className="mb-2 grid grid-cols-4 gap-4 font-bold">
              <div>Order ID</div>
              <div>Date</div>
              <div>Email</div>
              <div>Price</div>
            </div>
            {getRelevantOrders().map((order) => (
              <div key={order.id} className="grid grid-cols-4 gap-4 border-t py-2">
                <div>{order.id}</div>
                <div>{new Date(order.date).toLocaleDateString()}</div>
                <div>{order.email}</div>
                <div>{order.totalPrice}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderOverview2;
