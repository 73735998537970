import { createContext, useContext, useEffect, useState } from "react";

import { doc, getDoc, onSnapshot } from "firebase/firestore";

import { db } from "./firebase";

const initialUserData = {
  uid: "",
  email: "",
  displayName: "Laster ..",
  profileImage: "",
  cart: [],
  orderHistory: [
    {
      orderId: "",
    },
  ],
  lastOrder: [
    {
      headline: "Laster",
    },
  ],
};

export const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [userData, setUserData] = useState(initialUserData);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (userId) {
      const unsubscribeUser = fetchUserData(userId);
      return () => {
        unsubscribeUser();
      };
    }
  }, [userId]);

  const fetchUserData = (id) => {
    const docRef = doc(db, "users", id);

    const unsubscribe = onSnapshot(docRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userDocData = docSnapshot.data();
        setUserData(userDocData);
        userDocData.admin
          ? localStorage.setItem("admin", "true")
          : localStorage.setItem("admin", "false");

        // Hent lastOrder basert på siste orderId i orderHistory
        if (userDocData.orderHistory && userDocData.orderHistory.length > 0) {
          const lastOrderHistory =
            userDocData.orderHistory[userDocData.orderHistory.length - 1];
          const lastOrderRef = doc(db, "sales", lastOrderHistory.orderId);
          const lastOrderDoc = await getDoc(lastOrderRef);
          if (lastOrderDoc.exists()) {
            setUserData((prevUserData) => ({
              ...prevUserData,
              lastOrder: lastOrderDoc.data(),
            }));
          }
        }
      } else {
        console.log("Brukerdokumentet finnes ikke");
        setUserData(initialUserData);
      }
    });

    return unsubscribe;
  };

  return (
    <DataContext.Provider
      value={{
        userData,
        setUserData,
        userId,
        setUserId,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
