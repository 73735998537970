import React, { useEffect, useState } from "react";

import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ChevronRightIcon } from "../images/icons-fikse/chevron.svg";

interface Order {
  id: string;
  date: string;
  activity: {
    content: string;
  }[];
  orderInfo: {
    garmentType?: string;
    damageCategory?: string;
    item?: string;
    problem?: string;
    // Define other fields in the orderInfo object
  }[];
  // Define other fields in the Order type
}

const OrderTiles: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const db = getFirestore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrders = async () => {
      const email = localStorage.getItem("verifiedUid");

      if (email) {
        const ordersRef = collection(db, "workwearOrders");
        const q = query(ordersRef, where("email", "==", email));
        const snapshot = await getDocs(q);

        const fetchedOrders: Order[] = [];
        snapshot.forEach((doc) => {
          const orderData = doc.data() as Order;
          const lastActivity = orderData.activity[orderData.activity.length - 1];

          if (lastActivity.content !== "orderStateDelivered") {
            const { id, ...orderDataWithoutId } = orderData;
            fetchedOrders.push({
              id: doc.id,
              ...orderDataWithoutId,
            });
          }
        });

        setOrders(fetchedOrders);
      }
    };

    fetchOrders();
  }, [db]);

  const handleOrderClick = (orderId: string) => {
    navigate(`/orders/workwear/${orderId}`);
  };

  const getUniqueOrderInfo = (orderInfo: Order["orderInfo"]) => {
    const uniqueInfo: { [key: string]: number } = {};

    orderInfo.forEach((info) => {
      const key = `${info.problem} på ${info.item} `;
      uniqueInfo[key] = (uniqueInfo[key] || 0) + 1;
    });

    return Object.entries(uniqueInfo).map(([key, count]) => (
      <span key={key}>
        {count > 1 ? `${count}x ` : ""}
        {key}
      </span>
    ));
  };

  return (
    <div>
      <h2 className="mt-12 p-8 text-4xl sm:ml-8">
        {orders.length === 0 ? t("noActiveOrders") : t("myActiveOrders")}
      </h2>
      <div className="border-b border-black">
        {orders
          .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
          .map((order) => (
            <a
              key={order.id}
              href="#"
              className="flex cursor-pointer items-center justify-between border-t border-black p-4 px-8 text-lg hover:bg-fikseBlue sm:pl-16 sm:text-3xl"
              onClick={() => handleOrderClick(order.id)}
            >
              <div className="flex flex-col items-start">
                {order.orderInfo.length < 4 ? (
                  getUniqueOrderInfo(order.orderInfo)
                ) : (
                  <span>
                    {t("order")} {order.id.slice(0, 5)} {t("with")}{" "}
                    {order.orderInfo.length} {t("items")}
                  </span>
                )}
              </div>
              <ChevronRightIcon className="h-5 w-5 text-white" />
            </a>
          ))}
      </div>
    </div>
  );
};

export default OrderTiles;
