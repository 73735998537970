// components/DisplayOrderImage.tsx
import React, { useEffect, useRef, useState } from "react";

interface DisplayOrderImageProps {
  imageUrl: string;
}

const DisplayOrderImage: React.FC<DisplayOrderImageProps> = ({ imageUrl }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleImageClick = () => {
    setIsFullscreen(true);
  };

  const handleClose = () => {
    setIsFullscreen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    if (isFullscreen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFullscreen]);

  const ExpandIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="h-6 w-6 rounded-full bg-black bg-opacity-50 p-1 text-white"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
      />
    </svg>
  );

  return (
    <>
      <div className="relative max-h-[45vh] overflow-hidden md:max-h-[55vh]">
        <img
          src={imageUrl}
          alt="Resized"
          className="max-h-full w-full cursor-pointer object-contain"
          onClick={handleImageClick}
        />

        {/* Expand Icon */}
        <div className="absolute bottom-2 left-2" onClick={handleImageClick}>
          <ExpandIcon />
        </div>
      </div>

      {/* Fullscreen Modal */}
      {isFullscreen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="relative" ref={modalContentRef}>
            <button
              onClick={handleClose}
              className="absolute right-2 top-2 text-3xl font-bold text-white focus:outline-none"
            >
              &times;
            </button>
            <img
              src={imageUrl}
              alt="Fullscreen"
              className="max-h-screen max-w-full object-contain"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DisplayOrderImage;
