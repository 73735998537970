import { useEffect, useRef, useState } from "react";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";

// Importer useTranslation-hooken
import { app } from "../firebase";

interface AwaitingVerificationProps {
  email: string;
}

export default function AwaitingVerification({ email }: AwaitingVerificationProps) {
  const [verificationCode, setVerificationCode] = useState(["", "", "", "", "", ""]);
  const [resendEmail, setResendEmail] = useState(false);
  const [error, setError] = useState("");
  const emailSent = useRef(false);
  const { t } = useTranslation(); // Bruk oversettelse-hooken

  useEffect(() => {
    const uidFromStorage = localStorage.getItem("uid");
    const secretKeyFromStorage = localStorage.getItem("secretKey");
    const urlParams = new URLSearchParams(window.location.search);
    const codeFromURL = urlParams.get("code");

    if (!uidFromStorage || !secretKeyFromStorage) {
      // window.location.href = '/';
    } else {
      if (codeFromURL) {
        handleVerifyCode(codeFromURL, secretKeyFromStorage);
      } else if (!emailSent.current) {
        emailSent.current = true;
        sendInitialEmail(uidFromStorage);
      }
    }
  }, [email]);

  const generateNewSecretKey = () => {
    const newKey = Math.floor(100000 + Math.random() * 900000).toString(); // Generate a 6-digit code
    localStorage.setItem("secretKey", newKey);
    return newKey;
  };

  const verifyPublicKey = async (secretKey: string) => {
    try {
      const response = await fetch(`/verification/${secretKey}`);
      const data = await response.json();
      if (data.publicKey === secretKey) {
        const uid = localStorage.getItem("uid");
        if (uid) {
          localStorage.setItem("verifiedUid", uid);
          window.location.reload();
        }
      } else {
        console.log("Public key does not match secret key");
      }
    } catch (error) {
      console.error("Error verifying public key:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newVerificationCode = [...verificationCode];
      newVerificationCode[index] = value;
      setVerificationCode(newVerificationCode);

      if (value !== "" && index < verificationCode.length - 1) {
        document.getElementById(`verification-code-${index + 1}`)?.focus();
      }

      if (value === "" && index > 0) {
        document.getElementById(`verification-code-${index - 1}`)?.focus();
      }

      if (newVerificationCode.join("").length === 6) {
        handleVerifyCode(
          newVerificationCode.join(""),
          localStorage.getItem("secretKey") || ""
        );
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && verificationCode[index] === "" && index > 0) {
      const newVerificationCode = [...verificationCode];
      newVerificationCode[index - 1] = "";
      setVerificationCode(newVerificationCode);
      document.getElementById(`verification-code-${index - 1}`)?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData("text");
    if (/^\d{6}$/.test(paste)) {
      const newVerificationCode = paste.split("");
      setVerificationCode(newVerificationCode);
      handleVerifyCode(paste, localStorage.getItem("secretKey") || "");
    }
  };

  const handleVerifyCode = (code: string, secretKey: string) => {
    if (code === secretKey) {
      const uid = localStorage.getItem("uid");
      if (uid) {
        localStorage.setItem("verifiedUid", uid);
        window.location.reload();
      }
    } else {
      setError(t("verification.error")); // Bruk oversettelse
    }
  };

  const sendInitialEmail = async (email: string) => {
    const secretKey = generateNewSecretKey();

    if (email) {
      const functions = getFunctions(app, "europe-west1");
      const sendVerificationEmail = httpsCallable(functions, "sendVerificationEmail");

      try {
        console.log("Calling sendVerificationEmail function with:", {
          email,
          verificationCode: secretKey,
        });
        const result = await sendVerificationEmail({
          email,
          verificationCode: secretKey,
        });
        console.log("sendVerificationEmail result:", result);
        if ((result.data as any).success) {
          console.log("Email sent successfully");
        } else {
          console.error("Error sending email:", (result.data as any).error);
        }
      } catch (error) {
        console.error("Error calling function:", error);
      }
    } else {
      console.error("Email is missing");
    }

    setResendEmail(false);
    setTimeout(() => setResendEmail(false), 5000);
  };

  const handleResendEmail = async () => {
    setResendEmail(true);
    const email = localStorage.getItem("uid");
    sendInitialEmail(email || "");
  };

  return (
    <div className="flex min-h-full flex-col items-center justify-center bg-white p-4 sm:p-6 lg:p-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl tracking-tight text-gray-900">
            {t("verification.verifyEmail")}
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {t("verification.instructions")}
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <div className="flex justify-center space-x-2">
            {verificationCode.map((num, index) => (
              <input
                key={index}
                id={`verification-code-${index}`}
                type="text"
                maxLength={1}
                value={num}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                className="h-12 w-12 border border-gray-300 text-center shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
              />
            ))}
          </div>
          {error && <p className="text-center text-sm text-red-600">{error}</p>}
          <div className="mt-4 flex justify-center">
            <button
              onClick={handleResendEmail}
              className={`inline-flex justify-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#8CD7FF] focus:ring-offset-2 ${resendEmail ? "cursor-not-allowed" : ""}`}
              disabled={resendEmail}
            >
              {t("verification.resendEmail")}
            </button>
            {process.env.NODE_ENV === "development" && (
              <button
                onClick={() => {
                  localStorage.setItem("verifiedUid", localStorage.getItem("uid") || "");
                  window.location.reload();
                }}
                className={`ml-4 inline-flex justify-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#8CD7FF] focus:ring-offset-2 ${resendEmail ? "cursor-not-allowed" : ""}`}
                disabled={resendEmail}
              >
                {t("verification.skip")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
