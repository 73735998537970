import React, { useEffect, useState } from "react";

import { Timestamp, collection, getDocs, query, where } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import { sendSMS } from "../../components/contact";
import { db } from "../../firebase";
import { Order } from "../../pages/OrderPage";

interface OrderSummary extends Order {
  pageName: string | null;
}

const FikseAdminOverview: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date("2023-10-15"));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [orders, setOrders] = useState<OrderSummary[]>([]);
  const [loading, setLoading] = useState(true);
  const [uniquePages, setUniquePages] = useState<string[]>([]);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [excludeTestOrders, setExcludeTestOrders] = useState(true);

  const getPageName = (url: string | undefined) => {
    if (!url) return null;
    try {
      const urlWithoutHash = url.split("#")[0];
      const segments = urlWithoutHash.split("/").filter(Boolean);
      const lastSegment = segments[segments.length - 1];
      return lastSegment?.split(":")[0] || null;
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  };

  const isTestOrder = (order: Order) => {
    const testPhone = "97811811";
    const testEmails = ["simon@ntnu.no", "@fikse.co"];

    return (
      order.contactInfo?.phone === testPhone ||
      testEmails.some((email) => order.email?.toLowerCase().includes(email.toLowerCase()))
    );
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const ordersRef = collection(db, "b2cOrders");
      const q = query(ordersRef);
      const querySnapshot = await getDocs(q);

      const fetchedOrders = querySnapshot.docs.map((doc) => {
        const data = doc.data() as Order;
        return {
          ...data,
          id: doc.id,
          pageName: getPageName(data.orderCreationUrl),
        };
      });

      // Extract and deduplicate page names, removing nulls and undefined values
      const pageNames = fetchedOrders
        .map((order) => order.pageName)
        .filter(
          (pageName): pageName is string => pageName !== null && pageName !== undefined
        );
      const uniquePages = Array.from(new Set(pageNames));

      setUniquePages(uniquePages);
      setOrders(fetchedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const filteredOrders = orders
    .filter((order) => {
      const orderDate = new Date(order.date);
      const dateFilter = orderDate >= startDate && orderDate <= endDate;
      const pageFilter =
        selectedPages.length === 0 || selectedPages.includes(order.pageName || "");
      const testOrderFilter = !excludeTestOrders || !isTestOrder(order);
      return dateFilter && pageFilter && testOrderFilter;
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()); // Sort by date descending

  const pageOptions = uniquePages.map((page) => ({
    value: page,
    label: page,
  }));

  const calculateStats = (orders: OrderSummary[]) => {
    const totalOrders = orders.length;
    const totalPrice = orders.reduce(
      (sum, order) => sum + parseFloat(order.totalPrice || "0"),
      0
    );
    const averagePrice = totalOrders > 0 ? totalPrice / totalOrders : 0;

    return {
      totalOrders,
      totalPrice: totalPrice.toFixed(2),
      averagePrice: averagePrice.toFixed(2),
    };
  };

  return (
    <div className="min-h-screen p-4">
      {/* Add Statistics Panel */}
      <div className="mb-8 border-b border-black pb-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <input
            type="text"
            placeholder="Phone Number"
            className="rounded border border-black p-2"
            id="phoneNumber"
          />
          <input
            type="text"
            placeholder="Firebase_Notation_NO_SPACES"
            className="rounded border border-black p-2"
            id="messageTitle"
          />
          <textarea
            placeholder="Message Content"
            className="col-span-2 h-32 resize-y rounded border border-black p-2"
            id="messageContent"
          />
        </div>
        <button
          onClick={async () => {
            const phoneInput = document.getElementById("phoneNumber") as HTMLInputElement;
            const titleInput = document.getElementById(
              "messageTitle"
            ) as HTMLInputElement;
            const contentInput = document.getElementById(
              "messageContent"
            ) as HTMLTextAreaElement;

            await sendSMS(phoneInput.value, titleInput.value, contentInput.value);

            // Clear fields after sending
            phoneInput.value = "";
            titleInput.value = "";
            contentInput.value = "";
          }}
          className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
        >
          Send SMS
        </button>
      </div>
      <div className="mb-8 grid grid-cols-1 gap-4 sm:grid-cols-3">
        <div className="rounded-lg border border-black p-4">
          <h3 className="text-sm font-medium text-gray-500">Total Orders</h3>
          <p className="mt-1 text-3xl font-semibold">
            {calculateStats(filteredOrders).totalOrders}
          </p>
        </div>
        <div className="rounded-lg border border-black p-4">
          <h3 className="text-sm font-medium text-gray-500">Total Sales</h3>
          <p className="mt-1 text-3xl font-semibold">
            {calculateStats(filteredOrders).totalPrice} NOK
          </p>
        </div>
        <div className="rounded-lg border border-black p-4">
          <h3 className="text-sm font-medium text-gray-500">Average Order Value</h3>
          <p className="mt-1 text-3xl font-semibold">
            {calculateStats(filteredOrders).averagePrice} NOK
          </p>
        </div>
      </div>

      <div className="mb-8 space-y-4 border-b border-black pb-4">
        <div className="flex flex-wrap gap-4">
          <div className="w-full md:w-auto">
            <label className="block text-sm font-medium text-gray-700">From Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => date && setStartDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="w-full md:w-auto">
            <label className="block text-sm font-medium text-gray-700">To Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => date && setEndDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="w-full md:w-64">
            <label className="block text-sm font-medium text-gray-700">
              Filter by Page
            </label>
            <Select
              isMulti
              options={pageOptions}
              onChange={(selected) =>
                setSelectedPages(selected.map((option) => option.value))
              }
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="flex w-full items-center gap-2 md:w-auto">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <input
                type="checkbox"
                checked={excludeTestOrders}
                onChange={(e) => setExcludeTestOrders(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300"
              />
              Exclude Test Orders
            </label>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <div className="space-y-4">
          {filteredOrders.map((order) => (
            <div key={order.id} className="border-b border-black p-4">
              <div className="flex flex-wrap justify-between gap-4">
                <div>
                  <h3 className="text-lg font-medium">
                    Order ID:{" "}
                    <a
                      href={`/orders/${order.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 hover:underline"
                    >
                      {order.id.slice(0, 5)}
                    </a>
                  </h3>
                  <p>Customer: {order.contactInfo?.name || "N/A"}</p>
                  <p>Phone: {order.contactInfo?.phone}</p>
                  <p>
                    Date:{" "}
                    {new Date(order.date).toLocaleString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <p>Total Price: {order.totalPrice} NOK</p>
                </div>
                <div>
                  <p>Source: {order.pageName || "Unknown"}</p>
                  <p>
                    Status:{" "}
                    {order.activity && order.activity.length > 0
                      ? order.activity[order.activity.length - 1].content
                      : "No status"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FikseAdminOverview;
