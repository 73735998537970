import { useState } from "react";

import { Dialog } from "@headlessui/react";
import { doc, updateDoc } from "firebase/firestore";

import { db } from "../../firebase";

interface Retail {
  id: string;
  name: string;
  orgNumber: string;
  addresses: string[];
  contactPerson: {
    name: string;
    phone: string;
    email: string;
  };
}

interface EditRetailModalProps {
  retail: Retail;
  onClose: () => void;
  onSave: (updatedRetail: Retail) => void;
}

const isContactPersonKey = (key: string): key is keyof Retail["contactPerson"] => {
  return ["name", "phone", "email"].includes(key);
};

export function EditRetailModal({ retail, onClose, onSave }: EditRetailModalProps) {
  const [formData, setFormData] = useState(retail);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name.startsWith("contactPerson.")) {
      const key = name.split(".")[1];
      if (isContactPersonKey(key)) {
        setFormData({
          ...formData,
          contactPerson: { ...formData.contactPerson, [key]: value },
        });
      }
    } else if (name === "addresses") {
      setFormData({
        ...formData,
        addresses: value.split(",").map((address) => address.trim()),
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const retailRef = doc(db, "retail", formData.id);

    const updateData = {
      name: formData.name,
      orgNumber: formData.orgNumber,
      addresses: formData.addresses,
      contactPerson: formData.contactPerson,
    };

    await updateDoc(retailRef, updateData);
    onSave(formData);
  };

  return (
    <Dialog open={true} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
            <div className="">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900"
              >
                Edit Retailer
              </Dialog.Title>
              <form className="mt-2 space-y-4" onSubmit={handleSubmit}>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Org Number
                  </label>
                  <input
                    type="text"
                    name="orgNumber"
                    value={formData.orgNumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Addresses
                  </label>
                  <input
                    type="text"
                    name="addresses"
                    value={formData.addresses.join(", ")}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Person Name
                  </label>
                  <input
                    type="text"
                    name="contactPerson.name"
                    value={formData.contactPerson.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Person Phone
                  </label>
                  <input
                    type="text"
                    name="contactPerson.phone"
                    value={formData.contactPerson.phone}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Person Email
                  </label>
                  <input
                    type="email"
                    name="contactPerson.email"
                    value={formData.contactPerson.email}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="mt-5 flex justify-between space-x-4 sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center bg-[#8CD7FF] px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Save Changes
                  </button>
                  <button
                    onClick={onClose}
                    className="inline-flex w-full justify-center border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
