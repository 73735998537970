import React from "react";

import { useTranslation } from "react-i18next";

interface DeviationPromtProps {
  newPrice?: number;
  tailorsNote?: string;
  onAcceptClick: () => void;
  onDeclinedClick: () => void;
}

const DeviationPromt: React.FC<DeviationPromtProps> = ({
  newPrice,
  tailorsNote,
  onAcceptClick,
  onDeclinedClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className="text-base">
      <div className="w-full border-b border-t border-black bg-fikseBlue p-4 text-left text-base">
        {t("deviationPromt")}
      </div>
      <div className="flex w-full">
        <div className="flex-shrink-0 p-4">{newPrice} kr</div>
        <div className="flex-grow break-words border-l border-black p-4">
          {tailorsNote}
        </div>
      </div>
      <div className="mb-10 flex w-full cursor-pointer border-b border-t border-black">
        <button
          className="w-1/2 border-r border-black bg-fikseRed p-4 text-left"
          onClick={onDeclinedClick}
        >
          {t("deviationDecline")}
        </button>
        <button className="w-1/2 bg-fikseGreen p-4 text-left" onClick={onAcceptClick}>
          {t("deviationAccept")}
        </button>
      </div>
    </div>
  );
};

export default DeviationPromt;
